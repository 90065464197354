import { toast } from "react-toastify";
import api from "./api/api"
import { ApiErrorUtil } from "../Util/ApiErrorUtil";
import { createQueryParams } from "../Util/PageParamsUtil";

const post = async (dataPost) => {
    try {
        const { data } = await api.post("/comunidade", dataPost);
        toast.success(data);
        return data;
    } catch (error) {
        toast.error(error.message || "Erro desconhecido");
    }
}

const get = async (pageParams) => {
    try {
        const { data } = await api.get("/comunidade" + createQueryParams(pageParams));
        return data;
    } catch (error) {
        toast.error(error.message || "Erro desconhecido");
    }
}

const deleteData = async (id) => {
    try {
        const { data } = await api.delete(`/comunidade?id=${id}`);
        toast.success(data);
    } catch (error) {
        ApiErrorUtil.showError(error);
    }
}

export const comunidadeService = {
    get,
    deleteData,
    post
}