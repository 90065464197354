import React, { createContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Importe useNavigate
import { AccountService } from '../services/accountService';

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const navigate = useNavigate();
    const isCadastro = window.location.pathname.includes('cadastro');

    const login = async (userData) => {
        try {
            const user = await AccountService.login(userData);
            if (!user) return;
            setUser(user);
            localStorage.setItem('user', JSON.stringify(user));
            navigate('/');
        } catch (error) {
            console.error('Erro durante o login:', error);
        }
    };

    const logout = () => {
        setUser(null);
        localStorage.removeItem('user');
        navigate('/login');
    };

    useEffect(() => {
        const savedUser = localStorage.getItem('user');
        if (savedUser) {
            setUser(JSON.parse(savedUser));
        } else {
            if(!isCadastro){
                navigate('/login');
            }
        }
    }, []);

    return (
        <UserContext.Provider value={{ user, login, logout }}>
            {children}
        </UserContext.Provider>
    );
};
