import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { faArrowDown, faArrowLeft, faArrowUp, faCircleCheck, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { transacaoService } from '../../../services/transacaoService';
import { formatarDataParaInput } from '../../../Util/DataUtil';
import CurrencyInputField from 'react-currency-input-field';

const ModalEditarTransacao = ({ show, onHide, transacao }) => {
  const [transacaoAtual, setTransacaoAtual] = useState(null);
  const [validacaoErro, setValidacaoErro] = useState({
    valor: false,
    tipo: null,
    data: false
  });

  useEffect(() => {
    setTransacaoAtual({ ...transacao, tipo: transacao?.tipo?.toString() ?? null });
  }, [transacao]);

  const validarTransacao = () => {
    const valor = transacaoAtual?.valor?.toString() ?? "0";
    const valorParsed = parseFloat(valor.replace(',', '.').replace("-", ""));
    const erros = {
      valor: valor === '' || isNaN(valorParsed) || valorParsed <= 0,
      tipo: transacaoAtual.tipo == null || transacaoAtual.tipo < 0 || transacaoAtual.tipo > 1,
      data: isNaN(Date.parse(transacaoAtual.data))
    };

    setValidacaoErro(erros);
    return !Object.values(erros).some(Boolean);
  };

  const handleSalvarClick = async () => {
    if (!validarTransacao()) {
      return;
    }

    try {
      const transacaoParaSalvar = {
        ...transacaoAtual,
        valor: parseFloat(transacaoAtual.valor?.toString().replace(',', '.')),
        tipo: parseInt(transacaoAtual.tipo)
      };
      await transacaoService.post(transacaoParaSalvar);
      onHide(); // Fechar o modal
    } catch (error) {
      console.error('Erro ao salvar:', error);
    }
  };


  const handleTransacaoChange = (novaTransacao) => {
    setTransacaoAtual(novaTransacao);
  };

  return (
    <Modal size='md' show={show} onHide={onHide}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title><FontAwesomeIcon icon={faUser} /> Dados da transação</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Row>
          <Col md={6} className='mb-2'>
            <CurrencyInputField
              className={`form-control ${validacaoErro.valor ? 'is-invalid' : ''}`}
              prefix="R$"
              decimalSeparator=","
              groupSeparator="."
              allowNegativeValue={false}
              placeholder='R$ 0,00'
              value={transacaoAtual?.valor?.toString().replace('-', '')}
              onValueChange={(value) => handleTransacaoChange({ ...transacaoAtual, valor: value })}
            />
            {validacaoErro.valor && <div className="invalid-feedback">O valor não pode ser vazio ou igual a zero.</div>}
          </Col>
          <Col md={6} className='mb-2'>
            <Form.Control type='datetime-local' placeholder='Data' value={formatarDataParaInput(transacaoAtual?.data, true) || formatarDataParaInput(new Date(), true)} onChange={e => handleTransacaoChange({ ...transacaoAtual, data: e.target.value })} isInvalid={validacaoErro.data} />
            {validacaoErro.data && <div className="invalid-feedback">Data inválida.</div>}
          </Col>
          <Row>
            <Col xs={10} className={`mb-2`}>
              <Form.Select className={`${transacaoAtual?.tipo == 1 ? 'border border-danger' : ''} ${transacaoAtual?.tipo == 0 ? 'border border-success' : '' }`} value={transacaoAtual?.tipo || ""} onChange={e => handleTransacaoChange({ ...transacaoAtual, tipo: e.target.value })} isInvalid={validacaoErro.tipo}>
                <option value={null}>Selecione o tipo</option>
                <option value="0">Entrada</option>
                <option value="1">Saída</option>
              </Form.Select>
              {validacaoErro.tipo && <div className="invalid-feedback">Selecione o tipo da transação.</div>}
            </Col>
            <Col xs={2} className={`mb-2 d-flex align-items-center justify-content-center `}>
              {transacaoAtual?.tipo == 0 && (
                <FontAwesomeIcon icon={faArrowDown} size='2x' className={'text-success'} />
              )}

              {transacaoAtual?.tipo == 1 && (
                <FontAwesomeIcon icon={faArrowUp} size='2x' className={'text-danger'} />
              )}

            </Col>
          </Row>
          <Col md={12}>
            <Form.Control as={'textarea'} rows={2} type='text' placeholder='Descrição' value={transacaoAtual?.descricao || ""} onChange={e => handleTransacaoChange({ ...transacaoAtual, descricao: e.target.value })} />
          </Col>
        </Row>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}><FontAwesomeIcon icon={faArrowLeft} /> Voltar</Button>
        <Button variant="success" onClick={handleSalvarClick}><FontAwesomeIcon icon={faCircleCheck} /> Salvar</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalEditarTransacao;
