import React, { useContext, useEffect, useState } from 'react';
import { Table, Button, Row, Col, Modal, Form } from 'react-bootstrap';
import { faPlus, faFileUpload, faArrowLeft, faCheck, faDownload, faSearch, faTrash, faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import { arquivosDownloadService } from '../../../services/arquivosDownloadService';
import { getDataMenosDias } from '../../../Util/DataUtil';
import { UserContext } from '../../../Context/UserContext';

function PageDownloadArquivo() {
  const [pageListArquivos, setPageListArquivos] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [modalMode, setModalMode] = useState('upload'); // 'upload' or 'edit'
  const [modalTitle, setModalTitle] = useState('Upload de Arquivo');
  const [arquivoParaEdicao, setArquivoParaEdicao] = useState(null);
  const [descricao, setDescricao] = useState('');
  const [arquivo, setArquivo] = useState(null);
  const [termoBusca, setTermoBusca] = useState('');
  const [pageParams, setPageParams] = useState({
    termoBusca: '',
    numeroPagina: 1,
    dataInicial: getDataMenosDias(365), // Defina a data inicial como 30 dias atrás
    dataFinal: getDataMenosDias(0) // Defina a data final como a data atual
  });

  // Novo estado para controlar o visibilidade do modal de confirmação
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
  const [arquivoParaExcluir, setArquivoParaExcluir] = useState(null);

  const { user } = useContext(UserContext); // Obtenha o usuário do contexto

  useEffect(() => {
    fetchArquivos();
  }, [pageParams]);

  const fetchArquivos = async () => {
    try {
      const arquivosData = await arquivosDownloadService.get(pageParams);
      setPageListArquivos(arquivosData);
    } catch (error) {
      toast.error("Erro ao carregar os arquivos. Por favor, tente novamente.");
    }
  };

  const handleDownloadClick = (arquivo) => {
    arquivosDownloadService.downloadArquivo(arquivo?.id);
  };

  const handleNovoArquivoClick = () => {
    setModalMode('upload');
    setModalTitle('Upload de Arquivo');
    setShowModal(true);
  };

  const handleEditClick = (arquivo) => {
    if(user?.role != "adm") return;

    setArquivoParaEdicao(arquivo);
    setDescricao(arquivo.descricao);
    setModalMode('edit');
    setModalTitle('Editar Arquivo');
    setShowModal(true);
  };

  const handleConfirmModal = async () => {
    if (!descricao || (arquivoParaEdicao ? false : true && !arquivo)) {
      alert("Por favor, preencha a descrição e selecione um arquivo antes de continuar.");
      return;
    }

    if (arquivoParaEdicao) {
      await arquivosDownloadService.post({
        id: arquivoParaEdicao?.id ?? 0,
        descricao,
        arquivoBase64: null,//no backend será pego o arquivo já cadastrado
        extensao: null //no backend será pego o arquivo já cadastrado
      });
      setShowModal(false);
    } else {
      try {
        const reader = new FileReader();
        reader.onload = async () => {
          const base64String = btoa(reader.result);

          // Get the file extension
          const fileName = arquivo.name;
          const extensao = fileName.split('.').pop();

          await arquivosDownloadService.post({
            id: arquivoParaEdicao?.id ?? 0,
            descricao,
            arquivoBase64: base64String,
            extensao // Include the file extension
          });

          setShowModal(false);
          setArquivoParaEdicao(null);
          setDescricao('');
          fetchArquivos();
        };
        reader.readAsBinaryString(arquivo);
      } catch (error) {
        toast.error("Erro ao fazer upload do arquivo. Por favor, tente novamente.");
      }
    }
  };


  const handleCancelarModal = () => {
    setShowModal(false);
    setArquivoParaEdicao(null);
    setDescricao("");
  };

  const handlePesquisar = () => {
    setPageParams({
      ...pageParams,
      termoBusca: termoBusca
    });
  };

  const showConfirmDelete = (arquivo) => {
    setArquivoParaExcluir(arquivo);
    setShowConfirmDeleteModal(true);
  };

  const cancelarExclusao = () => {
    setShowConfirmDeleteModal(false);
    setArquivoParaExcluir(null);
  };

  const deleteData = async () => {
    try {
      await arquivosDownloadService.deleteData(arquivoParaExcluir.id);
      fetchArquivos();
      setShowConfirmDeleteModal(false);
      setArquivoParaExcluir(null);
    } catch (error) {
      toast.error("Erro ao excluir o arquivo. Por favor, tente novamente.");
    }
  };


  return (
    <Row className='d-flex justify-content-center'>
      <Col md={8} className="mb-3 justify-content-end">

        {user?.role == 'adm' && (
          <Col className='d-flex justify-content-end mb-3'>
            <Button variant="primary" onClick={handleNovoArquivoClick}><FontAwesomeIcon icon={faPlus} /> Novo Arquivo</Button>
          </Col>
        )}

        <h2>Arquivos</h2>
        <Form>
          <Form.Control
            type="text"
            placeholder="Qual o nome do arquivo que deseja encontrar?"
            value={termoBusca}
            onChange={(e) => {
              setTermoBusca(e.target.value);
              handlePesquisar(); // Chama a função de pesquisa sempre que algo for digitado no campo de entrada
            }}
          />
        </Form>

        <Row className='mt-3'>
          <Col>
            <Table striped hover>
              <thead>
                <tr>
                  <th>Descrição do arquivo</th>
                  <th>Ações</th>
                </tr>
              </thead>
              <tbody>
                {pageListArquivos?.itens?.map((arquivo, index) => (
                  <tr key={index} onClick={() => handleEditClick(arquivo)}>
                    <td>{arquivo.descricao}</td>
                    <td>
                      {user?.role == 'adm' &&
                        <Button className='me-2' variant="danger" onClick={(e) => { e.stopPropagation(); showConfirmDelete(arquivo); }}><FontAwesomeIcon icon={faTrash} /> </Button>
                      }
                      <Button variant="primary me-2" onClick={(e) => { e.stopPropagation(); handleDownloadClick(arquivo); }}><FontAwesomeIcon icon={faDownload} /> </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>

            {pageListArquivos?.itens?.length == 0 && (
              <h5 className='d-flex justify-content-center'>Nenhum resultado encontrado</h5>
            )}
          </Col>
        </Row>

        <Modal show={showModal} onHide={handleCancelarModal}>
          <Modal.Header closeButton>
            <Modal.Title>{modalTitle}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group controlId="formDescricao">
              <Form.Label>Descrição:</Form.Label>
              <Form.Control
                type="text"
                value={descricao}
                onChange={(e) => setDescricao(e.target.value)}
                placeholder="Digite uma descrição para o arquivo"
              />
              {!descricao && <div className="text-danger">Por favor, preencha a descrição.</div>}
            </Form.Group>

            <Form.Group controlId="formArquivo">
              <Form.Label>Arquivo:</Form.Label>
              <Form.Control
                type="file"
                required={!arquivoParaEdicao}
                onChange={(e) => setArquivo(e.target.files[0])}
              />

              {!arquivoParaEdicao && (
                <>
                  {!arquivo && <div className="text-danger">Por favor, selecione um arquivo.</div>}
                </>
              )}
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCancelarModal}><FontAwesomeIcon icon={faArrowLeft} /> Fechar</Button>
            <Button variant="success" onClick={handleConfirmModal}><FontAwesomeIcon icon={faCheck} /> Salvar</Button>
          </Modal.Footer>
        </Modal>

        {/* Modal de confirmação para exclusão de arquivo */}
        <Modal show={showConfirmDeleteModal} onHide={cancelarExclusao}>
          <Modal.Header closeButton>
            <Modal.Title>Confirmar Exclusão</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Você tem certeza que deseja excluir o arquivo "{arquivoParaExcluir?.descricao}"?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={cancelarExclusao}>Cancelar</Button>
            <Button variant="danger" onClick={deleteData}>Excluir</Button>
          </Modal.Footer>
        </Modal>

      </Col>
    </Row>
  );
}

export default PageDownloadArquivo;
