import React, { useEffect, useState, useCallback } from 'react';
import { Col, Row, Form, Button, Table } from 'react-bootstrap';
import { comunidadeService } from '../../services/comunidadeService';
import { relatorioService } from '../../services/relatorioService';
import { formatarDataParaInput, getDataMenosDias } from '../../Util/DataUtil';
import { inscricaoService } from '../../services/periodoInscricaoService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileInvoice, faPrint } from '@fortawesome/free-solid-svg-icons';

const RelatorioCandidato = () => {
  const today = new Date().toISOString().split('T')[0];

  const [dataInicio, setDataInicio] = useState(formatarDataParaInput(getDataMenosDias(30)));
  const [dataFim, setDataFim] = useState(today);
  const [comunidades, setComunidades] = useState([]);
  const [periodosInscricao, setPeriodosInscricao] = useState([]);
  const [comunidadeSelecionada, setComunidadeSelecionada] = useState(null);
  const [periodoInscricaoSelecionado, setPeriodoInscricaoSelecionado] = useState(null);
  const [dadosRelatorio, setDadosRelatorio] = useState([]);
  const [carregando, setCarregando] = useState(false);

  useEffect(() => {
    comunidadeService.get().then(resp => setComunidades(resp?.itens ?? []));
    inscricaoService.get().then(resp => setPeriodosInscricao(resp?.itens ?? []));
  }, []);

  const handleDataInicioChange = useCallback((event) => {
    setDataInicio(event.target.value);
  }, []);

  const handleDataFimChange = useCallback((event) => {
    setDataFim(event.target.value);
  }, []);

  const handleComunidadeChange = useCallback((event) => {
    setComunidadeSelecionada(event.target.value);
  }, []);

  const handlePeriodoInscricaoChange = useCallback((event) => {
    setPeriodoInscricaoSelecionado(event.target.value);
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    setCarregando(true);

    const params = {
      periodoInscricaoId: periodoInscricaoSelecionado,
      comunidadeId: comunidadeSelecionada
    };

    relatorioService.getCandidato(params).then((resp) => {
      setDadosRelatorio(resp ?? []);
      setCarregando(false);
    }).catch(() => {
      setCarregando(false);
    });
  };

  const handlePrint = () => {
    const printContent = document.getElementById('tabelaRelatorio').outerHTML;
    const newWindow = window.open('', '', 'width=800,height=600');
    const comunidade = comunidades.find(x => x.id == comunidadeSelecionada);
    const periodoInscricao = periodosInscricao.find(x => x.id == periodoInscricaoSelecionado);

    newWindow.document.write(`
      <html>
        <head>
          <title>Relatório de Candidatos</title>
          <style>
            table {
              width: 100%;
              border-collapse: collapse;
            }
            th, td {
              border: 1px solid black;
              padding: 8px;
              text-align: left;
            }
          </style>
        </head>
        <body>
        <h2>Relatório de Candidatos</h2>
        <p></p>

        <h4 style='margin: 0px; padding: 0px'><span style='font-weight: 300;'>Nome da comunidade: </span><strong>${comunidade?.nome}</strong></h4>
        <h4 style='margin: 0px; padding: 0px'><span style='font-weight: 300;'>Período de inscrição: </span>${periodoInscricao?.periodo}</strong></h4>
        <hr>
        <small>Gerado em ${new Date().toLocaleDateString() + ' às ' + new Date().toLocaleTimeString()}</small>
          ${printContent}
        </body>
      </html>
    `);
    newWindow.document.close();
    newWindow.print();
  };

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Row className="d-flex">
          {/* <Col md={4} className="d-flex align-items-center">
            <Form.Group controlId="formDataInicio" className="me-2">
              <Form.Label>Data de Início</Form.Label>
              <Form.Control
                type="date"
                value={dataInicio}
                onChange={handleDataInicioChange}
              />
            </Form.Group>
            <Form.Group controlId="formDataFim">
              <Form.Label>Data de Fim</Form.Label>
              <Form.Control
                type="date"
                value={dataFim}
                onChange={handleDataFimChange}
              />
            </Form.Group>
          </Col> */}
          <Col md={8} className='d-flex align-items-center'>
            <Form.Group controlId="formPeriodoInscricao">
              <Form.Label>Período de Inscrição</Form.Label>
              <Form.Select
                value={periodoInscricaoSelecionado}
                onChange={handlePeriodoInscricaoChange}
              >
                <option value={0}>Atual</option>
                {periodosInscricao?.map((periodo) => (
                  <option key={periodo.id} value={periodo.id}>
                    {periodo.periodo}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            <Form.Group controlId="formComunidade" className='ms-2'>
              <Form.Label>Comunidade</Form.Label>
              <Form.Select
                value={comunidadeSelecionada}
                onChange={handleComunidadeChange}
              >
                <option value={0}>Todas</option>
                {comunidades?.map((comunidade) => (
                  <option key={comunidade.id} value={comunidade.id}>
                    {comunidade.nome}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Col>

          <Col className='d-flex justify-content-end mt-2'>
            <Button variant="primary" type="submit">
              <FontAwesomeIcon icon={faFileInvoice}/> Gerar Relatório
            </Button>

            {dadosRelatorio?.length > 0 && (
              <Button className='ms-2' variant="secondary" onClick={handlePrint}>
              <FontAwesomeIcon icon={faPrint}/> Imprimir
              </Button>
            )}
          </Col>
        </Row>
        <br />
        <Row>
          <Col>
            <h2>Relatório de Candidatos</h2>
            {carregando ? (
              <div className="d-flex justify-content-center">
                <div className="spinner-border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            ) : (
              <>
                {dadosRelatorio?.length > 0 ? (
                  <>
                    <Table id="tabelaRelatorio" striped bordered hover>
                      <thead>
                        <tr>
                          <th>Nome do Candidato</th>
                          <th>Telefone</th>
                          <th>Telefone responsável</th>
                        </tr>
                      </thead>
                      <tbody>
                        {dadosRelatorio.map((item, index) => (
                          <tr key={index}>
                            <td>{item.nomeCandidato}</td>
                            <td>{item?.telefone}</td>
                            <td>{item?.telefoneResponsavel}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </>
                ) : (
                  <p className='text-center'>Nenhum dado disponível</p>
                )}
              </>
            )}
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default RelatorioCandidato;
