import React, { useState } from 'react';
import { faCalendar, faClock, faEye, faFileSignature, faPenToSquare, faTrashCan, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { formatarData } from '../../Util/DataUtil';
import { UserContext } from '../../Context/UserContext';
import { useContext } from 'react';
import ModalRegistroPresenca from '../Modal/RegistroPresenca/ModalRegistroPresenca';
import ModalListaRegistroPresenca from '../Modal/RegistroPresenca/ModalListaRegistroPresenca';

const CardCandidato = ({ candidato, onDeleteClick, onEditClick }) => {
    const { user } = useContext(UserContext);
    const [showModalRegistro, setShowModalRegistro] = useState(false);
    const [showModalLista, setShowModalLista] = useState(false);
    const [candidatoSelecionado, setCandidatoSelecionado] = useState(null);

    const handleLancarPresencaClick = (candidato) => {
        setCandidatoSelecionado(candidato);
        setShowModalRegistro(true);
    };

    const handleVerPresencaClick = (candidato) => {
        setCandidatoSelecionado(candidato);
        setShowModalLista(true);
    };

    const handleCloseListaModal = () => {
        setShowModalLista(false);
    };

    const handleCloseRegistroModal = () => {
        setShowModalRegistro(false);
      };

    const tooltip = (
        <Tooltip id="tooltip-data-cadastro">
            Data de Cadastro: {formatarData(candidato?.createdAt, true)}
        </Tooltip>
    );

    return (
        <>
            <div className='col-md-6 col-lg-4 mb-2'>
                <Card className='border-dark'>
                    <Card.Header className='position-relative'>
                        <span><FontAwesomeIcon icon={faUser} /> {candidato?.nome}</span>
                        <small className='d-block'>Matrícula <strong>n°{candidato?.id}</strong></small>
                    </Card.Header>
                    <Card.Body>
                        <span className='text-muted d-block'><FontAwesomeIcon icon={faCalendar} /> Nascimento: {formatarData(candidato?.dataNascimento)}</span>
                    </Card.Body>
                    <Card.Footer className='d-flex justify-content-between'>
                        <div className='d-flex align-items-center'>
                            <OverlayTrigger
                                placement="top"
                                delay={{ show: 250, hide: 400 }}
                                overlay={tooltip}
                            >
                                <span style={{ fontSize: '13px' }} className='text-muted d-block'>
                                    <small><FontAwesomeIcon icon={faClock} /> <strong>{formatarData(candidato?.createdAt, true)}</strong></small>
                                </span>
                            </OverlayTrigger>
                        </div>

                        {user.role === 'adm' && (
                            <div>
                                <Button variant='outline-danger' onClick={() => onDeleteClick(candidato)}><FontAwesomeIcon icon={faTrashCan} /></Button>
                                <Button variant='outline-primary' className='ms-1' onClick={() => onEditClick(candidato)}><FontAwesomeIcon icon={faPenToSquare} /></Button>
                            </div>
                        )}

                        {user.role === 'catequista' && (
                            <div>
                                <Button variant='outline-info' className='me-1' onClick={() => handleVerPresencaClick(candidato)}><FontAwesomeIcon icon={faEye} /> </Button>
                                <Button variant='outline-primary' onClick={() => handleLancarPresencaClick(candidato)}><FontAwesomeIcon icon={faFileSignature} /></Button>
                            </div>
                        )}
                    </Card.Footer>
                </Card>

            </div>
            <ModalRegistroPresenca
                show={showModalRegistro}
                onHide={handleCloseRegistroModal}
                candidatoId={candidatoSelecionado ? candidatoSelecionado.id : null}
            />
            <ModalListaRegistroPresenca
                show={showModalLista}
                onHide={handleCloseListaModal}
                candidatoId={candidatoSelecionado ? candidatoSelecionado.id : null}
            />
        </>
    );
};

export default CardCandidato;
