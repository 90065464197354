import React, { useContext, useState } from 'react';
import { Card, Col, Row, Spinner } from 'react-bootstrap'; // Importe o Spinner do react-bootstrap
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import LogoImage from '../../resources/img/logo.jpg';
import { UserContext } from '../../Context/UserContext';
import { faLock, faRightToBracket, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function Login() {
    const { login } = useContext(UserContext);
    const [formData, setFormData] = useState({
        login: '',
        senha: '',
        lembrar: false
    });
    const [isLoading, setIsLoading] = useState(false); // Adicione o estado isLoading

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        const val = type === 'checkbox' ? checked : value;
        setFormData({ ...formData, [name]: val });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true); // Define isLoading como true quando o formulário é enviado
        await login(formData);
        setIsLoading(false); // Define isLoading como false quando a solicitação é concluída
    };

    return (
        <Row className='d-flex justify-content-center'>
            <Col sm={8} lg={6} xl={4}>
                <Card>
                    <Card.Body>
                        <Row className='d-flex justify-content-center'>
                            <Col xs={4} sm={6} lg={4}>
                                <Card.Img className='mx-auto' variant="top" src={LogoImage} />
                            </Col>
                        </Row>
                        <Form onSubmit={handleSubmit}>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label className='m-0'><FontAwesomeIcon icon={faUser} /> Usuário</Form.Label>
                                <Form.Control type="text" name="login" placeholder="Informe o nome de usuário" onChange={handleChange} />
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formBasicPassword">
                                <Form.Label className='m-0'><FontAwesomeIcon icon={faLock} /> Senha</Form.Label>
                                <Form.Control type="password" name="senha" placeholder="Informe a senha" onChange={handleChange} />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicCheckbox">
                                <Form.Check type="checkbox" name="lembrar" label="Manter conectado" onChange={handleChange} />
                            </Form.Group>
                            <Button variant="primary" type="submit" className='w-100' disabled={isLoading}>
                                {isLoading ? <Spinner animation="border" size="sm" /> : <FontAwesomeIcon icon={faRightToBracket} />} Entrar
                            </Button>
                        </Form>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    );
}

export default Login;
