import { toast } from "react-toastify";
import api from "./api/api"
import { createQueryParams } from "../Util/PageParamsUtil";
import { ApiErrorUtil } from "../Util/ApiErrorUtil";

const post = async (dataPost) => {
    try {
        const { data } = await api.post("/registroPresenca", dataPost);
        return data;
    } catch (error) {
        ApiErrorUtil.showError(error);
    }
}

const get = async (pageParams, candidatoId) => {
    try {
        const { data } = await api.get(`/registroPresenca${createQueryParams(pageParams)}&candidatoId=${candidatoId}`);
        return data;
    } catch (error) {
        ApiErrorUtil.showError(error);
    }
}

const getId = async (id) => {
    try {
        const { data } = await api.get(`/registroPresenca/id/${id}`);
        return data;
    } catch (error) {
        ApiErrorUtil.showError(error);
    }
}

const deleteData = async (id) => {
    try {
        const { data } = await api.delete(`/registroPresenca?id=${id}`);
        toast.success(data);
    } catch (error) {
        ApiErrorUtil.showError(error);
    }
}

export const registroPresencaService = {
    post,
    get,
    getId,
    deleteData
}