import React, { useEffect, useState } from 'react';
import { Button, Form, Row, Col, Card, Spinner, Alert, FormGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faUser, faCalendar, faPhone, faUserTie, faUserAlt, faMapMarkerAlt,
  faRoad, faHouseUser, faCity, faChurch, faQuestionCircle, faEnvelopeOpenText,
  faBuilding, faCheckCircle, faHashtag, faFaceSmile, faEnvelope, faRing, faWater,
  faFile,
  faImage,
  faWarning,
  faPlus,
  faMinus
} from '@fortawesome/free-solid-svg-icons';
import InputMask from 'react-input-mask';
import { isValidDate, isValidPhoneNumber, validarDataPeriodo } from '../../Util/Validadores';
import { candidatoService } from '../../services/candidatoService';
import { toast } from 'react-toastify';
import { comunidadeService } from '../../services/comunidadeService';
import { createPageParams } from '../../Util/PageParamsUtil';
import { DomUtil } from '../../Util/DomUtil';
import './style.css';
import { formatarData, formatarDataParaInput, getCurrentDate, getDataMenosDias, obterAnos, obterDiasDoMes } from '../../Util/DataUtil';
import { ibgeService } from '../../services/api/ibgeService';
import { inscricaoService } from '../../services/periodoInscricaoService';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { imageUtil } from '../../Util/imageUtil';
import ModalVerDocumento from '../../components/Modal/Documento/ModalVerDocumento';
import { linkUtil } from '../../Util/LinkUtil';

function PageCadastro({ candidatoEditar }) {
  const [validated, setValidated] = useState(false);
  const [formWasSubmited, setFormWasSubmited] = useState(false);
  const [requesting, setRequesting] = useState(false);
  const [cidades, setCidades] = useState([]);
  const [cidadeSelecionada, setCidadeSelecionada] = useState('');
  const [idMatricula, setIdMatricula] = useState(0);
  const [comunidades, setComunidades] = useState([]);
  const [comunidadeSelecionada, setComunidadeSelecionada] = useState({});
  const [dataNascimentoValida, setDataNascimentoValida] = useState(false);
  const [telefoneValido, setTelefoneValido] = useState(false);
  const [telefoneResponsavelValido, setTelefoneResponsavelValido] = useState(false);
  const [nome, setNome] = useState('');
  const [dataNascimento, setDataNascimento] = useState(formatarDataParaInput(getCurrentDate()));
  const [telefone, setTelefone] = useState('');
  const [nomeMae, setNomeMae] = useState('');
  const [nomePai, setNomePai] = useState(null);
  const [telefoneResponsavel, setTelefoneResponsavel] = useState('');
  const [cep, setCep] = useState('');
  const [rua, setRua] = useState('');
  const [numero, setNumero] = useState('');
  const [bairro, setBairro] = useState('');
  const [cidade, setCidade] = useState('');
  const [participaMissas, setParticipaMissas] = useState(null);
  const [comunidadeParticipa, setComunidadeParticipa] = useState(null);
  const [email, setEmail] = useState('');
  // const [profissao, setProfissao] = useState(null);
  const [isBatizado, setIsBatizado] = useState(null);
  const [isBatizadoNaIgrejaCatolica, setIsBatizadoNaIgrejaCatolica] = useState(null);
  // const [isCasado, setIsCasado] = useState(null);
  const [igrejaBatismo, setIgrejaBatismo] = useState(null);
  const [documentoBatizado, setDocumentoBatizado] = useState(null);
  const [primeiraComunhao, setPrimeiraComunhao] = useState(null);
  const [documentoPrimeiraComunhao, setDocumentoPrimeiraComunhao] = useState(null);
  const [showOutraIgrejaCasamentoInput, setShowOutraIgrejaCasamentoInput] = useState(false);
  const [estadoCivil, setEstadoCivil] = useState(null);
  const [outraIgrejaCasamento, setOutraIgrejaCasamento] = useState(null);
  const [certidaoCasamento, setCertidaoCasamento] = useState(null);
  const [erroPrimeiraComunhao, setErroPrimeiraComunhao] = useState('');
  const [showModalVerDocumento, setShowModalVerDocumento] = useState(false);

  const [periodoInscricaoEmAberto, setPeriodoInscricaoEmAberto] = useState(null);
  const [ultimoPeriodoInscricao, setUltimoPeriodoInscricao] = useState(null);
  const [imagemVisualizar, setImagemVisualziar] = useState(null);

  const [diaNasc, setDiaNasc] = useState(new Date().getDate());
  const [mesNasc, setMesNasc] = useState(new Date().getMonth() + 1);
  const [anoNasc, setAnoNasc] = useState(new Date().getFullYear());

  const [diaNascPadrinho, setDiaNascPadrinho] = useState(new Date().getDate());
  const [mesNascPadrinho, setMesNascPadrinho] = useState(new Date().getMonth() + 1);
  const [anoNascPadrinho, setAnoNascPadrinho] = useState(new Date().getFullYear());


  const [padrinhoNome, setPadrinhoNome] = useState('');
  const [padrinhoTelefone, setPadrinhoTelefone] = useState('');
  const [padrinhoDataNascimento, setPadrinhoDataNascimento] = useState('');
  const [padrinhoEstadoCivil, setPadrinhoEstadoCivil] = useState(null);
  const [padrinhoCrismado, setPadrinhoCrismado] = useState(null);
  const [padrinhoDocumentoCrisma, setPadrinhoDocumentoCrisma] = useState(null);
  const [padrinhoDocumentoCrismaBase64, setPadrinhoDocumentoCrismaBase64] = useState(null);

  const [padrinhoBanco, setPadrinhoBanco] = useState(null);

  const [mostrarCadastroPadrinho, setMostrarCadastroPadrinho] = useState(false);


  // const EstadoCivil = {
  //   NoCivilEReligiosoIgrejaCatolica: 0,
  //   NoCivilEReligiosoOutraIgreja: 1,
  //   SoNoCivil: 2,
  //   VivemJuntos: 3
  // };

  const handleDiaNascChange = (e) => {
    setDiaNasc(e.target.value);

    let dataArray = dataNascimento.split('-');
    let diaSelecionado = e.target.value;

    if (diaSelecionado < 10) {
      diaSelecionado = '0' + diaSelecionado;
    }

    dataArray[2] = diaSelecionado;
    const novaData = dataArray?.join('-');
    setDataNascimento(novaData);
  }

  const handleMesNascChange = (e) => {
    setMesNasc(e.target.value);

    let dataArray = dataNascimento.split('-');
    let mesSelecionado = e.target.value;

    if (mesSelecionado < 10) {
      mesSelecionado = '0' + mesSelecionado;
    }

    dataArray[1] = mesSelecionado;
    const novaData = dataArray?.join('-');
    setDataNascimento(novaData);
  }

  const handleAnoNascChange = (e) => {
    setAnoNasc(e.target.value);

    let dataArray = dataNascimento.split('-');
    let anoSelecionado = e.target.value;

    if (anoSelecionado < 10) {
      anoSelecionado = '0' + anoSelecionado;
    }

    dataArray[0] = anoSelecionado;
    const novaData = dataArray?.join('-');
    setDataNascimento(novaData);
  }

  const handleDiaNascPadrinhoChange = (e) => {
    setDiaNascPadrinho(e.target.value);

    let dataArray = padrinhoDataNascimento.split('-');
    let diaSelecionado = e.target.value;

    if (diaSelecionado < 10) {
      diaSelecionado = '0' + diaSelecionado;
    }

    dataArray[2] = diaSelecionado;
    const novaData = dataArray?.join('-');
    setPadrinhoDataNascimento(novaData);
  }

  const handleMesNascPadrinhoChange = (e) => {
    setMesNascPadrinho(e.target.value);

    let dataArray = padrinhoDataNascimento.split('-');
    let mesSelecionado = e.target.value;

    if (mesSelecionado < 10) {
      mesSelecionado = '0' + mesSelecionado;
    }

    dataArray[1] = mesSelecionado;
    const novaData = dataArray?.join('-');
    setPadrinhoDataNascimento(novaData);
  }

  const handleAnoNascPadrinhoChange = (e) => {
    setAnoNascPadrinho(e.target.value);

    let dataArray = padrinhoDataNascimento.split('-');
    let anoSelecionado = e.target.value;

    if (anoSelecionado < 10) {
      anoSelecionado = '0' + anoSelecionado;
    }

    dataArray[0] = anoSelecionado;
    const novaData = dataArray?.join('-');
    setPadrinhoDataNascimento(novaData);
  }

  const handleOpenModalVerDocumento = (image) => {
    setImagemVisualziar(image);
    setShowModalVerDocumento(true);
  };

  const handleCloseModal = () => {
    setShowModalVerDocumento(false);
    setImagemVisualziar(null);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }
  const handleSubmit = (event) => {
    event.preventDefault();

    if (!candidatoEditar?.id) {
      if (formWasSubmited) return;
    }

    const form = event.currentTarget;
    setValidated(true);

    if (form.checkValidity() && validar()) {
      const padrinho = {
        id: candidatoEditar?.padrinhoId,
        crismado: padrinhoCrismado == "Sim",
        createdAt: padrinhoBanco?.createdAt,
        dataNascimento: padrinhoDataNascimento,
        documentoCrismaBase64: padrinhoCrismado == "Sim" ? padrinhoDocumentoCrismaBase64 : null,
        estadoCivil: Number(padrinhoEstadoCivil),
        nome: padrinhoNome,
        telefone: padrinhoTelefone,
      };

      const formData = {
        id: candidatoEditar?.id ?? 0,
        periodoInscricaoId: periodoInscricaoEmAberto?.id,
        createdAt: getCurrentDate(),
        nome,
        dataNascimento,
        telefone,
        nomeMae,
        nomePai: (nomePai?.length ?? 0) == 0 ? null : nomePai,
        telefoneResponsavel: (telefoneResponsavel?.length ?? 0) == 0 ? null : telefoneResponsavel,
        cep,
        rua,
        numero,
        bairro,
        cidade: cidadeSelecionada,
        participaMissas,
        comunidadeParticipa,
        comunidadeId: comunidadeSelecionada?.id ?? 0,
        email: (email?.length ?? 0) == 0 ? null : email,
        estadoCivil: Number(estadoCivil),
        // profissao,
        igrejaBatismo,
        igrejaCasamento: outraIgrejaCasamento,
        batizadoNaIgrejaCatolica: isBatizadoNaIgrejaCatolica ?? false,
        batizado: isBatizado,
        // casado: isCasado == "Sim",
        documentoCasamentoBase64: certidaoCasamento,
        documentoBatizadoBase64: documentoBatizado,
        documentoPrimeiraComunhaoBase64: documentoPrimeiraComunhao,
        primeiraComunhao: primeiraComunhao == "Sim",
        padrinho: (padrinho?.nome?.length ?? 0) > 0 ? padrinho : null
      };

      try {
        setRequesting(true);
        candidatoService.post(formData).then((response) => {
          if (response) {
            // if (primeiraComunhao === 'Sim' && !documentoPrimeiraComunhao) {
            //   toast.warning("Favor providenciar o documento e entrar em contato com Verônica (21) 98835-0766 ou Cidinho (21) 98835-0767.");
            // }
            setIdMatricula(response?.id ?? 0);
            if (!candidatoEditar?.id) {
              setFormWasSubmited(true);
            }
            setValidated(false);
          }
          setRequesting(false);

          if (response?.id && candidatoEditar?.id) {
            toast.success("Candidado atualizado com sucesso");
          }
          scrollToTop();
        });

      } catch (error) {
        toast.error("Erro desconhecido");
      }
    } else {
      event.stopPropagation();
    }
  };

  const validar = () => {
    let valido = false;

    if (isValidDate(dataNascimento) || validarDataPeriodo(dataNascimento)) {
      valido = true;
      setDataNascimentoValida(true);
    } else {
      setDataNascimentoValida(false);
      valido = false;
    }

    if (isValidPhoneNumber(telefone)) {
      valido = true;
      setTelefoneValido(true);
    } else {
      setTelefoneValido(false);
      valido = false;
    }

    return valido;
  }

  const handleChangeCidade = (e) => {
    setCidadeSelecionada(e.target.value);
  }

  const handlePrimeiraComunhaoChange = (e) => {
    const valorSelecionado = e?.target?.value;
    // Verifica se o valor selecionado é uma opção válida
    if (valorSelecionado === 'Sim' || valorSelecionado === 'Não') {
      setPrimeiraComunhao(valorSelecionado);

      if (valorSelecionado != "Sim") {
        setDocumentoPrimeiraComunhao(null);
      }

      // Limpa a mensagem de erro se uma opção válida for selecionada
      setErroPrimeiraComunhao('');
    } else {
      // Define a mensagem de erro se uma opção inválida for selecionada
      setErroPrimeiraComunhao('Por favor, selecione uma opção válida.');
    }
  };

  const handleFileChangeDocumentoBatizado = (e) => {
    const file = e.target.files[0];
    if (file) {
      // Verificar se o arquivo é uma imagem
      if (!file.type.startsWith('image/')) {
        // Se não for uma imagem, exibir uma mensagem de erro
        toast.error("O arquivo selecionado não é uma imagem");
        e.target.value = [];
        return;
      }

      const reader = new FileReader();
      reader.onload = () => {
        // Passar o objeto de arquivo para a função reduzirTamanho
        imageUtil.reduzirTamanho(file)
          .then(base64String => {
            toast.success("Documento de batismo anexado com sucesso.");
            setDocumentoBatizado(base64String);
          })
          .catch(error => {
            console.error("Erro ao redimensionar imagem:", error);
          });
      };
      reader.readAsDataURL(file);
    }
  };


  const handleFileChangeDocumentoPadrinhoCrismaBase64 = (e) => {
    const file = e.target.files[0];
    if (file) {
      // Verificar se o arquivo é uma imagem
      if (!file.type.startsWith('image/')) {
        // Se não for uma imagem, exibir uma mensagem de erro
        toast.error("O arquivo selecionado não é uma imagem");
        e.target.value = [];
        return;
      }

      const reader = new FileReader();
      reader.onload = () => {
        // Passar o objeto de arquivo para a função reduzirTamanho
        imageUtil.reduzirTamanho(file)
          .then(base64String => {
            toast.success("Documento de crisma anexado com sucesso.");
            setPadrinhoDocumentoCrismaBase64(base64String);
          })
          .catch(error => {
            console.error("Erro ao redimensionar imagem:", error);
          });
      };
      reader.readAsDataURL(file);
    }
  };

  const handleFileChangeDocumentoPrimeiraComunhao = (e) => {
    const file = e.target.files[0];
    if (file) {
      // Verificar se o arquivo é uma imagem
      if (!file.type.startsWith('image/')) {
        // Se não for uma imagem, exibir uma mensagem de erro
        toast.error("O arquivo selecionado não é uma imagem");
        e.target.value = [];
        return;
      }

      const reader = new FileReader();
      reader.onload = () => {
        // Pass the file object to reduzirTamanho function
        imageUtil.reduzirTamanho(file)
          .then(base64String => {
            setDocumentoPrimeiraComunhao(base64String);
          })
          .catch(error => {
            console.error("Error resizing image:", error);
          });
      };
      reader.readAsDataURL(file);
    }
  };

  const handleFileChangeCertidaoCasamento = (e) => {
    const file = e.target.files[0];
    if (file) {
      // Verificar se o arquivo é uma imagem
      if (!file.type.startsWith('image/')) {
        // Se não for uma imagem, exibir uma mensagem de erro
        toast.error("O arquivo selecionado não é uma imagem");
        e.target.value = [];
        return;
      }

      const reader = new FileReader();
      reader.onload = () => {
        // Pass the file object to reduzirTamanho function
        imageUtil.reduzirTamanho(file)
          .then(base64String => {
            toast.success("Certidão de casamento anexada com sucesso.");
            setCertidaoCasamento(base64String);
          })
          .catch(error => {
            console.error("Error resizing image:", error);
          });
      };
      reader.readAsDataURL(file);
    }
  };


  useEffect(() => {
    if (padrinhoCrismado == null) return;

    if (padrinhoCrismado == "Não") {
      setPadrinhoDocumentoCrismaBase64(null);
      setPadrinhoDocumentoCrisma(null);
    }
  }, [padrinhoCrismado]);

  useEffect(() => {
    if (estadoCivil == 1) {
      setShowOutraIgrejaCasamentoInput(true);
      setEstadoCivil(1);
      setIgrejaBatismo('Outra igreja');
    } else {
      setIgrejaBatismo(null);
      setShowOutraIgrejaCasamentoInput(false);
      setOutraIgrejaCasamento(null);
    }
  }, [estadoCivil]);

  const handleCheckboxChangeEstadoCivil = (e) => {
    const checkboxId = e.target.id;

    document.querySelectorAll('input[type="checkbox"]').forEach((checkbox) => {
      if (checkbox.id !== checkboxId) {
        checkbox.checked = false;
      }
    });

    if (checkboxId === 'casadoOutraIgrejaCasamento') {
      setShowOutraIgrejaCasamentoInput(e.target.checked);
      setEstadoCivil(1);
      setIgrejaBatismo('Outra igreja');
      // Limpar o campo de outra igreja caso o checkbox seja desmarcado
      if (!e.target.checked) {
        setOutraIgrejaCasamento(null);
      }
    } else {

      if (checkboxId == 'casadoIgrejaCatolica') {
        setEstadoCivil(0);
      } else if (checkboxId == 'casadoCivil') {
        setEstadoCivil(2);
      } else if (checkboxId == 'casadoVivemJuntos') {
        //vivem juntos
        setEstadoCivil(3);
      } else {
        setEstadoCivil(4);
      }

      // Desmarcar outros checkboxes e atualizar o estado conforme necessário
      // document.querySelectorAll('input[type="checkbox"]').forEach((checkbox) => {
      //   console.log(checkbox.id);
      //   if (checkbox.id !== checkboxId) {
      //     checkbox.checked = false;
      //   }
      // });
      setIgrejaBatismo(null);
      setShowOutraIgrejaCasamentoInput(false);
      setOutraIgrejaCasamento('');
    }
  };

  useEffect(() => {
    inscricaoService.obterEmAberto().then((resp) => {
      if (resp?.encerrado && (!candidatoEditar?.id)) {
        setUltimoPeriodoInscricao(resp);
      } else {
        setPeriodoInscricaoEmAberto(resp);
        comunidadeService.get(createPageParams()).then(response => {
          if (response) {
            setComunidades(response?.itens || []);
          }
        });

        if ((candidatoEditar?.id ?? 0) > 0) {
          candidatoService.getId(candidatoEditar.id).then(resp => {
            const candidatoBanco = resp;

            setPadrinhoBanco(candidatoBanco?.padrinho)

            setIdMatricula(candidatoBanco?.id);
            setNome(candidatoBanco.nome);

            const dtNasc = formatarDataParaInput(candidatoBanco.dataNascimento);
            setDataNascimento(dtNasc);

            const dtArray = dtNasc.split('-');
            const diaNasc = Number(dtArray[2]);
            const mesNasc = Number(dtArray[1]);
            const anoNasc = Number(dtArray[0]);

            setDiaNasc(diaNasc);
            setMesNasc(mesNasc);
            setAnoNasc(anoNasc);


            const dtNascPadrinho = formatarDataParaInput(candidatoBanco.padrinho?.dataNascimento);
            setPadrinhoDataNascimento(dtNascPadrinho);

            const dtArrayPadrinho = dtNascPadrinho.split('-');
            const diaNascPadrinho = Number(dtArrayPadrinho[2]);
            const mesNascPadrinho = Number(dtArrayPadrinho[1]);
            const anoNascPadrinho = Number(dtArrayPadrinho[0]);

            setDiaNascPadrinho(diaNascPadrinho);
            setMesNascPadrinho(mesNascPadrinho);
            setAnoNascPadrinho(anoNascPadrinho);

            setPadrinhoEstadoCivil(candidatoBanco.padrinho?.estadoCivil?.toString() || null);
            setPadrinhoNome(candidatoBanco.padrinho?.nome);
            setPadrinhoTelefone(candidatoBanco.padrinho?.telefone);
            setPadrinhoCrismado(candidatoBanco?.padrinho?.crismado ? "Sim" : "Não");

            if (candidatoBanco?.padrinho?.documentoCrismaBase64?.length > 0) {
              setPadrinhoDocumentoCrismaBase64('data:image/jpeg;base64,' + candidatoBanco?.padrinho?.documentoCrismaBase64);
              setPadrinhoDocumentoCrisma('data:image/jpeg;base64,' + candidatoBanco?.padrinho?.documentoCrismaBase64);
            }


            setTelefone(candidatoBanco.telefone);
            setPrimeiraComunhao(candidatoBanco.primeiraComunhao ? "Sim" : "Não");
            // setIsCasado(candidatoBanco.casado ? "Sim" : "Não");
            setNomeMae(candidatoBanco.nomeMae);
            setNomePai(candidatoBanco.nomePai || null);
            setTelefoneResponsavel(candidatoBanco.telefoneResponsavel || null);
            setCep(candidatoBanco.cep);
            setCidade(candidatoBanco.cidade);
            setRua(candidatoBanco.rua);
            setNumero(candidatoBanco.numero);
            setBairro(candidatoBanco.bairro);
            setIsBatizadoNaIgrejaCatolica(candidatoBanco.batizadoNaIgrejaCatolica);
            setParticipaMissas(candidatoBanco.participaMissas);
            setComunidadeParticipa(candidatoBanco.comunidadeParticipa);
            setEmail(candidatoBanco.email || null);
            setEstadoCivil(candidatoBanco.estadoCivil?.toString() || null);
            // setProfissao(candidatoBanco.profissao || '');
            setIsBatizado(candidatoBanco.batizado || false);
            setCidadeSelecionada(candidatoBanco.cidade);

            //carregar os documentos aqui

            if (candidatoBanco.documentoBatizadoBase64?.length > 0) {
              setDocumentoBatizado('data:image/jpeg;base64,' + candidatoBanco.documentoBatizadoBase64);
            }

            if (candidatoBanco.documentoPrimeiraComunhaoBase64?.length > 0) {
              setDocumentoPrimeiraComunhao('data:image/jpeg;base64,' + candidatoBanco.documentoPrimeiraComunhaoBase64);
            }

            if (candidatoBanco.documentoCasamentoBase64?.length > 0) {
              setCertidaoCasamento('data:image/jpeg;base64,' + candidatoBanco.documentoCasamentoBase64);
            }

          });
        }

        setPadrinhoDataNascimento(formatarDataParaInput(new Date()))
      }
    });

  }, []);

  useEffect(() => {
    if (candidatoEditar) {
      setComunidadeSelecionada(comunidades.find(c => c.id == candidatoEditar?.comunidadeId));
    }
  }, [comunidades]);


  useEffect(() => {
    if (candidatoEditar?.id > 0) return;
    const cepFormatado = Number(cep?.trim()?.replace("-", "") ?? 0);

    if (isNaN(cepFormatado) || cepFormatado.toString().length !== 8) return;

    const fetchAddress = async () => {
      try {
        const response = await fetch(`https://viacep.com.br/ws/${cepFormatado}/json`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const json = await response.json();

        setBairro(json.bairro || "");
        setCidadeSelecionada(json.localidade || "");
        setRua(`${json.logradouro}${json.complemento ? ' - ' + json.complemento : ''}`);
      } catch (error) {
        console.error('Failed to fetch address:', error);
      }
    };

    fetchAddress();
  }, [cep]);

  useEffect(() => {
    const carregarCidades = async () => {
      try {
        const data = await ibgeService.obterCidades('RJ');
        setCidades(data.map(cidade => cidade.nome));
      } catch (error) {
        console.error('Erro ao carregar cidades:', error);
      }
    };

    carregarCidades();
  }, []);

  useEffect(() => {
    if (isValidPhoneNumber(telefone)) {
      setTelefoneValido(true);
    } else {
      setTelefoneValido(false);
    }
  }, [telefone]);

  useEffect(() => {
    if (formWasSubmited) {
      // desabilitarTudo();
    }
  }, [formWasSubmited]);

  // useEffect(() => {
  //   if (!(isCasado == "Sim")) {
  //     setEstadoCivil(null);
  //   }
  // }, [isCasado])

  const desabilitarTudo = () => {
    DomUtil.desabilitarElementos(document.querySelectorAll('input'));
    DomUtil.definirBordaZeroBoostrap(document.querySelectorAll('input'));
    DomUtil.definirCorFundo(document.querySelectorAll('input'));
  }

  return (
    <>
      {formWasSubmited && !candidatoEditar && (
        <>
          <Card
            bg='success'
            text='white'
            className='p-0 shadow border-0'
          >
            <Card.Body className='d-flex flex-column p-3'>
              <FontAwesomeIcon size='2x' className='mb-1' icon={faCheckCircle} />
              <Card.Title className='text-center'>Cadastro realizado com sucesso.</Card.Title>
              <p className='m-0 text-center'>Abaixo seguem os dados da sua matrícula</p>
            </Card.Body>
          </Card>

          <span className='mt-3 d-block border p-3 rounded-3'>
            <span className='d-block '>Em caso de dúvida entrar em contato com:</span>
            <span>Verônica</span>: <a className='me-2 text-primary text-decoration-none' target='_blank' href={linkUtil.gerarLinkWhatsappDuvidas('21988350766', idMatricula)}><FontAwesomeIcon icon={faWhatsapp} /> (21) 98835-0766</a>
            <br></br>
            <span> Cidinho</span>: <a target='_blank' className='mx-1 text-primary text-decoration-none' href={linkUtil.gerarLinkWhatsappDuvidas('21988350767)', idMatricula)}><FontAwesomeIcon icon={faWhatsapp} /> (21) 98835-0767</a>
          </span>

          {
            ((!documentoBatizado && isBatizado) || (!documentoPrimeiraComunhao && primeiraComunhao == 'Sim') || (!certidaoCasamento && estadoCivil != 4)) &&
            <>
              <br />
              <p className='text-danger'>Foi verificado a ausência dos seguintes documentos:</p>
              <ul className='text-danger'>
                {(!documentoBatizado && isBatizado) && (
                  <li><strong>Certidão de batizado</strong></li>
                )}
                {(!documentoPrimeiraComunhao && primeiraComunhao == 'Sim') && (
                  <li variant='warning'><strong>Documento da primeira comunhão</strong></li>
                )}
                {(!certidaoCasamento) && (
                  <li variant='warning'><strong>Certidão de casamento</strong></li>
                )}
              </ul>
              <p className='text-danger'><FontAwesomeIcon icon={faWarning} /> Favor entrar em contato com os números informados acima!</p>
            </>
          }
        </>
      )
      }

      {(periodoInscricaoEmAberto || candidatoEditar?.id) && (
        <Form noValidate validated={validated} onSubmit={handleSubmit} className={`${candidatoEditar ? '' : 'p-2'}`}>
          <Row className={`d-flex justify-content-center border-0 rounded-3 ${candidatoEditar ? '' : 'p-2 shadow-lg'}`} as={Card}>
            <Col md={12}>
              <Row>
                {formWasSubmited || candidatoEditar ? (
                  <Col xs={12} md={7} className="mt-2">
                    <Form.Group>
                      <Form.Label className="m-0" style={{ fontSize: '18px' }}><strong><FontAwesomeIcon icon={faHashtag} /> Matrícula</strong></Form.Label>
                      <Form.Control type="text" className='text-dark border-dark border-0' style={{ fontWeight: 'bold', 'backgroundColor': '#fff', fontSize: '16px' }} disabled id='idMatricula' value={idMatricula} />
                    </Form.Group>
                  </Col>
                ) : (
                  <div className='text-center mb-3'>
                    <h5 className='mt-2'><strong>Inscrição Crisma Raiz</strong></h5>
                    <span>De {formatarData(periodoInscricaoEmAberto.dataInicio)} <span></span>
                      {periodoInscricaoEmAberto.dataFim && (
                        <>
                          até {formatarData(periodoInscricaoEmAberto.dataFim)}
                        </>
                      )}
                      {!periodoInscricaoEmAberto.dataFim && (
                        <>
                          em diante
                        </>
                      )}
                    </span>
                  </div>
                )
                }

                <Col xs={12} md={7} className="mt-2">
                  <Form.Group controlId="validationNome">
                    <Form.Label className="m-0 mb-1"><FontAwesomeIcon icon={faUser} /> Nome completo do candidato <span className='text-danger'>*</span></Form.Label>
                    <Form.Control maxLength={250} minLength={5} required type="text" placeholder="Nome completo" disabled={formWasSubmited} value={nome} onChange={(e) => setNome(e.target.value)} />
                    <Form.Control.Feedback type="invalid">
                      Por favor, insira um nome válido.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <Col xs={12} md={5} className="mt-2">
                  <Form.Group controlId="validationDataNascimento">
                    <Form.Label className="m-0 mb-1"><FontAwesomeIcon icon={faCalendar} /> Data de Nascimento <span className='text-danger'>*</span></Form.Label>

                    <div className='d-flex justify-content-between'>
                      <label className='d-flex flex-column'>
                        Dia
                        <select className='form-select form-select-sm' value={diaNasc} onChange={handleDiaNascChange} disabled={formWasSubmited}>
                          {obterDiasDoMes(mesNasc).map((dia) => (
                            <option key={dia} value={dia}>{dia}</option>
                          ))}
                        </select>
                      </label>
                      <label className='d-flex flex-column'>
                        Mês
                        <select className='form-select form-select-sm' value={mesNasc} onChange={handleMesNascChange} disabled={formWasSubmited}>
                          <option value={1}>Janeiro</option>
                          <option value={2}>Fevereiro</option>
                          <option value={3}>Março</option>
                          <option value={4}>Abril</option>
                          <option value={5}>Maio</option>
                          <option value={6}>Junho</option>
                          <option value={7}>Julho</option>
                          <option value={8}>Agosto</option>
                          <option value={9}>Setembro</option>
                          <option value={10}>Outubro</option>
                          <option value={11}>Novembro</option>
                          <option value={12}>Dezembro</option>
                        </select>
                      </label>
                      <label className='d-flex flex-column'>
                        Ano
                        <select className='form-select form-select-sm' value={anoNasc} onChange={handleAnoNascChange} disabled={formWasSubmited}>
                          {obterAnos().map((ano) => (
                            <option key={ano} value={ano}>{ano}</option>
                          ))}
                        </select>
                      </label>
                    </div>

                    {/* <Form.Control
                      maxLength={250}
                      type="date"
                      // placeholder="dd/mm/aaaa"
                      required
                      disabled={formWasSubmited}
                      value={dataNascimento}
                      onChange={(e) => setDataNascimento(e.target.value)}
                    />
                    {!dataNascimentoValida && <Form.Control.Feedback type="invalid">
                      Por favor, insira a data de nascimento válida.
                    </Form.Control.Feedback>} */}
                  </Form.Group>
                </Col>

                <Col xs={12} md={4} className="mt-3">
                  <Form.Group controlId="validationTelefone">
                    <Form.Label className="m-0"><FontAwesomeIcon icon={faPhone} /> Telefone <span className='text-danger'>*</span></Form.Label>
                    <InputMask mask="(99) 99999-9999" required disabled={formWasSubmited} value={telefone} onChange={(e) => setTelefone(e.target.value)}>
                      {(inputProps) => <Form.Control {...inputProps} type="text" placeholder="(99) 99999-9999" />}
                    </InputMask>
                    {!telefoneValido && <Form.Control.Feedback type="invalid">
                      Por favor, insira um número de telefone válido.
                    </Form.Control.Feedback>}
                  </Form.Group>
                </Col>

                <Col xs={12} md={4} className="mt-3">
                  <Form.Group controlId="validationEmail">
                    <Form.Label className="m-0"><FontAwesomeIcon icon={faEnvelope} /> Email</Form.Label>
                    <Form.Control
                      type="email"
                      // required
                      placeholder="Email"
                      disabled={formWasSubmited}
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <Form.Control.Feedback type="invalid">
                      Por favor, insira um email válido.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>

                {/* <Col xs={12} md={4} className="mt-3">
                  <Form.Group controlId="validationEstadoCivil">
                    <Form.Label className="m-0"><FontAwesomeIcon icon={faRing} /> Estado Civil</Form.Label>
                    <Form.Select
                      required
                      disabled={formWasSubmited}
                      value={estadoCivil}
                      onChange={(e) => setEstadoCivil(e.target.value)}
                    >
                      <option value="">Selecione o estado civil</option>
                      <option value="Solteiro">Solteiro</option>
                      <option value="Casado">Casado</option>
                      <option value="Divorciado">Divorciado</option>
                      <option value="Viúvo">Viúvo</option>
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      Por favor, selecione um estado civil.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col> */}


                <Col xs={12} md={4} className="mt-3">
                  <Form.Group controlId="validationIsBatizado">
                    <Form.Label className="m-0"><FontAwesomeIcon icon={faWater} /> É batizado? <span className='text-danger'>*</span></Form.Label>
                    <Form.Select
                      required
                      disabled={formWasSubmited}
                      value={isBatizado === true ? "Sim" : isBatizado === false ? "Não" : ""}
                      onChange={(e) => setIsBatizado(e.target.value === "Sim" ? true : e.target.value === "Não" ? false : '')}
                    >
                      <option value="">Selecione uma opção</option>
                      <option value="Sim">Sim</option>
                      <option value="Não">Não</option>
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      Por favor, escolha uma opção.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>


                {isBatizado && (
                  <Col xs={12} md={4} className="mt-3">
                    <Form.Group controlId="validationIsBatizadoNaIgrejaCatolica">
                      <Form.Label className="m-0"><FontAwesomeIcon icon={faWater} /> Batizado na Igreja Católica Apostólica Romana? <span className='text-danger'>*</span></Form.Label>
                      <Form.Select
                        required
                        disabled={formWasSubmited}
                        value={isBatizadoNaIgrejaCatolica === true ? "Sim" : isBatizadoNaIgrejaCatolica === false ? "Não" : ""}
                        onChange={(e) => setIsBatizadoNaIgrejaCatolica(e.target.value === "Sim")}
                      >
                        <option value="">Selecione uma opção</option>
                        <option value="Sim">Sim</option>
                        <option value="Não">Não</option>
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        Por favor, escolha uma opção.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                )}

                {isBatizado && !isBatizadoNaIgrejaCatolica && (
                  <Col xs={12} md={4} className="mt-3">
                    <Form.Group controlId="validationIgrejaBatismo">
                      <Form.Label className="m-0"><FontAwesomeIcon icon={faChurch} /> Em qual igreja foi batizado? <span className='text-danger'>*</span></Form.Label>
                      <Form.Control
                        maxLength={250}
                        type="text"
                        placeholder="Igreja de Batismo"
                        disabled={formWasSubmited}
                        value={igrejaBatismo}
                        onChange={(e) => setIgrejaBatismo(e.target.value)}
                      />
                      <Form.Control.Feedback type="invalid">
                        Por favor, insira a igreja de batismo.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                )}

                {isBatizado == true && isBatizadoNaIgrejaCatolica == true && (
                  <Col xs={12} md={4} className="mt-3">
                    <Form.Group controlId="validationDocumentoBatizado">
                      <Form.Label className="m-0"><FontAwesomeIcon icon={faFile} /> Documento de Batizado</Form.Label>
                      <Form.Control type="file" size="md"
                        disabled={formWasSubmited}
                        onChange={handleFileChangeDocumentoBatizado}
                      />
                      <Form.Control.Feedback type="invalid">
                        Por favor, selecione um arquivo válido.
                      </Form.Control.Feedback>
                    </Form.Group>

                    {!documentoBatizado && (
                      <small className='text-warning'>
                        Se não tiver o documento entrar em contato com: Verônica <a target='_blank' href={linkUtil.gerarLinkWhatsappDuvidas('21988350766')}>(21) 98835-0766</a> ou Cidinho <a target='_blank' href={linkUtil.gerarLinkWhatsappDuvidas('21988350767')}>(21) 98835-0767</a>)
                      </small>
                    )}

                    {documentoBatizado && (
                      <Button className='mt-1' size='sm' onClick={() => handleOpenModalVerDocumento(documentoBatizado)}>
                        <FontAwesomeIcon icon={faImage} /> Ver documento
                      </Button>
                    )}
                  </Col>
                )}

                <Col xs={12} className='mt-3'>
                  <Form.Group controlId="validationPrimeiraComunhao">
                    <Form.Label><FontAwesomeIcon icon={faChurch} /> Primeira Comunhão: <span className='text-danger'>*</span></Form.Label>
                    <Form.Select
                      required
                      value={primeiraComunhao}
                      onChange={handlePrimeiraComunhaoChange}
                      disabled={formWasSubmited}>
                      <option value="">Selecione uma opção</option>
                      <option value="Sim">Sim</option>
                      <option value="Não">Não</option>
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      Informe se fez ou não a primeira comunhão.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>

                {primeiraComunhao === 'Sim' && (
                  <Col xs={12} md={4} className="mt-3">
                    <Form.Group controlId="validationDocumentoPrimeiraComunhao">
                      <Form.Label><FontAwesomeIcon icon={faFile} /> Documento da Primeira Comunhão</Form.Label>
                      <Form.Control
                        type="file"
                        size="md"
                        className='mb-1'
                        disabled={formWasSubmited}
                        onChange={handleFileChangeDocumentoPrimeiraComunhao}
                      />
                      <Form.Control.Feedback type="invalid">
                        Por favor, selecione um arquivo válido.
                      </Form.Control.Feedback>

                      {!documentoPrimeiraComunhao && (
                        <small className='text-warning'>
                          Se não tiver o documento entrar em contato com: Verônica <a target='_blank' href={linkUtil.gerarLinkWhatsappDuvidas('21988350766')}>(21) 98835-0766</a> ou Cidinho <a target='_blank' href={linkUtil.gerarLinkWhatsappDuvidas('21988350767')}>(21) 98835-0767</a>)
                        </small>
                      )}
                      {/* <Form.Control.Feedback type="invalid">
                        <span className='alert alert-warning'>{erroPrimeiraComunhao}</span>
                      </Form.Control.Feedback> */}
                    </Form.Group>
                    {documentoPrimeiraComunhao && (
                      <Button className='mt-1' size='sm' onClick={() => handleOpenModalVerDocumento(documentoPrimeiraComunhao)}>
                        <FontAwesomeIcon icon={faImage} /> Ver documento
                      </Button>
                    )}
                  </Col>
                )}

                <Col xs={12} className='mt-3'>
                  {/* <Form.Label><strong><FontAwesomeIcon icon={faRing} /> Estado civil: </strong></Form.Label> */}


                  <FormGroup>
                    <Form.Label><FontAwesomeIcon icon={faRing} /> Estado Civil <span className='text-danger'>*</span></Form.Label>
                    <Form.Select
                      required
                      value={estadoCivil}
                      onChange={(e) => setEstadoCivil(e.target.value)}
                      disabled={formWasSubmited}>
                      <option value="">Selecione o estado civil</option>
                      <option value="0">Casado(a) no civil e religioso (igreja católica)</option>
                      <option value="1">Casado(a) no civil e religioso (outra igreja)</option>
                      <option value="2">Casado(a) só no civil</option>
                      <option value="3">Vivem juntos</option>
                      <option value="4">Solteiro(a)</option>
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      Informe o estado civil.
                    </Form.Control.Feedback>
                  </FormGroup>
                  <div className='mt-2'>
                    {/* <Form.Check className='mb-2'
                      type="checkbox"
                      id="casadoIgrejaCatolica"
                      label="No civil e no religioso (Igreja Católica Apostólica Romana)"
                      disabled={formWasSubmited}
                      onChange={handleCheckboxChangeEstadoCivil}
                      value="Igreja Católica Apostólica Romana"
                      checked={estadoCivil == 0}
                    />
                    <Form.Check className='mb-2'
                      type="checkbox"
                      id="casadoOutraIgrejaCasamento"
                      label="No civil e no religioso (outra igreja, qual?)"
                      disabled={formWasSubmited}
                      checked={estadoCivil == 1}
                      onChange={handleCheckboxChangeEstadoCivil}
                    /> */}
                    {showOutraIgrejaCasamentoInput && (
                      <FormGroup>
                        <Form.Label><FontAwesomeIcon icon={faChurch} /> Igreja <span className='text-danger'>*</span></Form.Label>
                        <Form.Control className='mb-2'
                          type="text"
                          placeholder="Qual igreja foi o casamento?"
                          disabled={formWasSubmited}
                          onChange={(e) => setOutraIgrejaCasamento(e.target.value)}
                        />
                      </FormGroup>
                    )}
                    {/* <Form.Check className='mb-2'
                      type="checkbox"
                      id="casadoCivil"
                      label="Só no civil"
                      disabled={formWasSubmited}
                      checked={estadoCivil == 2}
                      onChange={handleCheckboxChangeEstadoCivil}
                    />
                    <Form.Check className='mb-2'
                      type="checkbox"
                      id="casadoVivemJuntos"
                      label="Vivem Juntos"
                      disabled={formWasSubmited}
                      checked={estadoCivil == 3}
                      onChange={handleCheckboxChangeEstadoCivil}
                    />
                    <Form.Check
                      type="checkbox"
                      id="solteiro"
                      label="Solteiro"
                      disabled={formWasSubmited}
                      checked={estadoCivil == 4}
                      onChange={handleCheckboxChangeEstadoCivil}
                    /> */}
                  </div>
                </Col>

                {estadoCivil != 4 && estadoCivil != 3 && estadoCivil != null && (
                  <Col xs={12} className='mt-2'>
                    <Form.Label>Certidão de casamento:</Form.Label>
                    <Form.Control
                      type='file'
                      id="certidaoCasamento"
                      label="Selecione a certidão de casamento"
                      onChange={handleFileChangeCertidaoCasamento}
                      disabled={formWasSubmited}
                    />
                    {!certidaoCasamento && (
                      <small className='text-warning'>
                        Se não tiver o documento entrar em contato com: Verônica <a target='_blank' href={linkUtil.gerarLinkWhatsappDuvidas('21988350766')}>(21) 98835-0766</a> ou Cidinho <a target='_blank' href={linkUtil.gerarLinkWhatsappDuvidas('21988350767')}>(21) 98835-0767</a>)
                      </small>
                    )}
                    {certidaoCasamento && (
                      <Button className='mt-1' size='sm' onClick={() => handleOpenModalVerDocumento(certidaoCasamento)}>
                        <FontAwesomeIcon icon={faImage} /> Ver documento
                      </Button>
                    )}
                  </Col>
                )}

                <Col xs={12} md={4} className="mt-3">
                  <Form.Group controlId="validationNomeMae">
                    <Form.Label className="m-0"><FontAwesomeIcon icon={faUserTie} /> Nome completo da mãe <span className='text-danger'>*</span></Form.Label>
                    <Form.Control maxLength={250} required type="text" placeholder="Nome completo da mãe" disabled={formWasSubmited} value={nomeMae} onChange={(e) => setNomeMae(e.target.value)} />
                    <Form.Control.Feedback type="invalid">
                      Por favor, insira o nome da mãe.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <Col xs={12} md={4} className="mt-3">
                  <Form.Group controlId="validationNomePai">
                    <Form.Label maxLength={250} className="m-0"><FontAwesomeIcon icon={faUserAlt} /> Nome completo do pai (opcional)</Form.Label>
                    <Form.Control type="text" placeholder="Nome completo do pai" disabled={formWasSubmited} value={nomePai} onChange={(e) => setNomePai(e.target.value)} />
                  </Form.Group>
                </Col>

                <Col xs={12} md={4} className="mt-3">
                  <Form.Group controlId="validationTelefoneResponsavel">
                    <Form.Label className="m-0"><FontAwesomeIcon icon={faPhone} /> Telefone do Responsável (se menor de idade)</Form.Label>
                    <InputMask mask="(99) 99999-9999" className="form-control" disabled={formWasSubmited} value={telefoneResponsavel} onChange={(e) => setTelefoneResponsavel(e.target.value)}>
                      {(inputProps) => <Form.Control {...inputProps} disabled={formWasSubmited} type="text" placeholder="(99) 99999-9999" />}
                    </InputMask>
                  </Form.Group>
                </Col>

                <Col xs={12} md={4} className="mt-3">
                  <Form.Group controlId="validationCEP">
                    <Form.Label className="m-0"><FontAwesomeIcon icon={faMapMarkerAlt} /> CEP <span className='text-danger'>*</span></Form.Label>
                    <InputMask mask="99999-999" className="form-control" required disabled={formWasSubmited} value={cep} onChange={(e) => setCep(e.target.value)}>
                      {(inputProps) => <Form.Control {...inputProps} disabled={formWasSubmited} type="text" placeholder="12345-678" />}
                    </InputMask>
                    <Form.Control.Feedback type="invalid">
                      Por favor, insira um CEP válido.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <Col xs={12} md={4} className="mt-3">
                  <Form.Group controlId="validationRua">
                    <Form.Label className="m-0"><FontAwesomeIcon icon={faRoad} /> Rua <span className='text-danger'>*</span></Form.Label>
                    <Form.Control maxLength={250} required type="text" placeholder="Rua" disabled={formWasSubmited} value={rua} onChange={(e) => setRua(e.target.value)} />
                    <Form.Control.Feedback type="invalid">
                      Por favor, insira uma rua válida.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <Col xs={12} md={4} className="mt-3">
                  <Form.Group controlId="validationNumero">
                    <Form.Label className="m-0"><FontAwesomeIcon icon={faHouseUser} /> Número <span className='text-danger'>*</span></Form.Label>
                    <Form.Control maxLength={250} required type="text" placeholder="00" disabled={formWasSubmited} value={numero} onChange={(e) => setNumero(e.target.value)} />
                    <Form.Control.Feedback type="invalid">
                      Por favor, insira um número válido.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <Col xs={12} md={4} className="mt-3">
                  <Form.Group controlId="validationBairro">
                    <Form.Label className="m-0"><FontAwesomeIcon icon={faBuilding} /> Bairro <span className='text-danger'>*</span></Form.Label>
                    <Form.Control maxLength={250} required type="text" placeholder="Bairro" disabled={formWasSubmited} value={bairro} onChange={(e) => setBairro(e.target.value)} />
                    <Form.Control.Feedback type="invalid">
                      Por favor, insira um bairro válido.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <Col xs={12} md={4} className="mt-3">
                  <Form.Group controlId="validationCidade">
                    <Form.Label className="m-0"><FontAwesomeIcon icon={faCity} /> Cidade <span className='text-danger'>*</span></Form.Label>
                    <Form.Select id="selectCidade" as="select" required disabled={formWasSubmited} value={cidadeSelecionada} onChange={handleChangeCidade}>
                      <option value="">Selecione uma cidade</option>
                      {cidades.map((c, idx) => (
                        <option key={idx} value={c}>{c}</option>
                      ))}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      Por favor, selecione uma cidade válida.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <Col xs={12} className='d-flex flex-column mt-4'>
                  <Form.Label>
                    <strong>
                      <FontAwesomeIcon icon={faQuestionCircle} /> Participa das Missas Dominicais com frequência? <span className='text-danger'>*</span>
                    </strong>
                  </Form.Label>
                  {formWasSubmited ? (
                    <span>{participaMissas ? "SIM" : "NÃO"}</span>
                  ) : (
                    <>
                      <FormGroup>
                        <Form.Select
                          required
                          disabled={formWasSubmited}
                          value={participaMissas === true ? "SIM" : participaMissas === false ? "NÃO" : ""}
                          onChange={(e) => {
                            const value = e.target.value === "SIM" ? true : e.target.value === "NÃO" ? false : '';
                            setParticipaMissas(value);
                          }}
                        >
                          <option value="">Escolha uma opção</option>
                          <option value="SIM">SIM (participa das missas)</option>
                          <option value="NÃO">NÃO (não participa das missas)</option>
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">
                          Informe se participa ou não das missas com frequência.
                        </Form.Control.Feedback>
                      </FormGroup>
                    </>
                  )}
                </Col>

                <Col xs={12} md={6} className='mt-3'>
                  <Form.Group controlId="validationComunidade">
                    <Form.Label><strong><FontAwesomeIcon icon={faChurch} /> Comunidade que participa <span className='text-danger'>*</span></strong></Form.Label>
                    {formWasSubmited ? (
                      <span style={{ fontSize: '16px' }} className='d-block'>{comunidadeParticipa}</span>
                    ) : (
                      <Form.Select
                        required
                        disabled={formWasSubmited}
                        value={comunidadeParticipa}
                        onChange={(e) => setComunidadeParticipa(e.target.value)}
                      >
                        <option value="">Selecionar comunidade</option>
                        {comunidades && comunidades.map((comunidade, idx) => (
                          <option key={idx} value={comunidade.nome}>{comunidade.nome}</option>
                        ))}
                      </Form.Select>
                    )}
                  </Form.Group>
                </Col>

                <Col xs={12} className='mt-3'>
                  <Form.Label><strong><FontAwesomeIcon icon={faChurch} /> Comunidade que irá fazer a Crisma: <span className='text-danger'>*</span></strong></Form.Label>
                  {formWasSubmited ? (
                    <span style={{ fontSize: '16px' }} className='d-block'>{comunidadeSelecionada?.nome || ""}</span>
                  ) : (
                    <>
                      <Form.Select
                        required
                        disabled={formWasSubmited}
                        value={comunidadeSelecionada?.nome || ""}
                        onChange={(e) => {
                          const selectedComunidade = comunidades?.find((c) => c.nome === e.target.value);
                          setComunidadeSelecionada(selectedComunidade);
                        }}
                      >
                        <option value="">Selecionar onde irá fazer crisma</option>
                        {comunidades && comunidades.map((comunidade, idx) => (
                          <option key={idx} value={comunidade.nome}>{comunidade.nome}</option>
                        ))}
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        Selecione a comunidade onde irá fazer o Crisma.
                      </Form.Control.Feedback>
                    </>
                  )}
                </Col>


                <Col xs={12} className='mt-4'>
                  <hr />
                  <div className='d-flex justify-content-between'>
                    <Form.Label><strong><h5><FontAwesomeIcon icon={faUserTie} /> Padrinho <small style={{ fontSize: '12.5px' }} className='text-warning'>(não obrigatório)</small></h5></strong></Form.Label>
                    {!candidatoEditar?.id &&
                      <Button onClick={(e => { setMostrarCadastroPadrinho(!mostrarCadastroPadrinho) })} className='ms-2' variant={mostrarCadastroPadrinho ? 'outline-danger' : 'outline-success'}><FontAwesomeIcon icon={mostrarCadastroPadrinho ? faMinus : faPlus} /> {mostrarCadastroPadrinho ? 'Não informar padrinho' : 'Informar padrinho'} </Button>
                    }
                  </div>
                  {(mostrarCadastroPadrinho || candidatoEditar?.id) &&
                    <>
                      <FormGroup className='mb-2'>
                        <Form.Label>Nome completo</Form.Label>
                        <Form.Control
                          type="text"
                          value={padrinhoNome}
                          placeholder='Nome completo do padrinho'
                          onChange={(e) => setPadrinhoNome(e.target.value)}
                          disabled={formWasSubmited}
                        />
                        <Form.Control.Feedback type="invalid">
                          Informe o nome do padrinho.
                        </Form.Control.Feedback>
                      </FormGroup>
                      <FormGroup className='mb-2'>
                        <Form.Label>Telefone</Form.Label>
                        <InputMask mask="(99) 99999-9999" required={padrinhoNome?.length > 0} disabled={formWasSubmited} value={padrinhoTelefone} onChange={(e) => setPadrinhoTelefone(e.target.value)}>
                          {(inputProps) => <Form.Control {...inputProps} type="text" placeholder="(99) 99999-9999" />}
                        </InputMask>
                        {/* <Form.Control
                        type="text"
                        required
                        value={padrinhoTelefone}
                        placeholder='Telefone ou celular do padrinho'
                        onChange={(e) => setPadrinhoTelefone(e.target.value)}
                        disabled={formWasSubmited}
                      /> */}
                        <Form.Control.Feedback type="invalid">
                          Informe o telefone do padrinho
                        </Form.Control.Feedback>
                      </FormGroup>
                      <FormGroup className='mb-2'>
                        <Form.Label>Data de Nascimento</Form.Label>
                        <div className='d-flex justify-content-between'>
                          <label className='d-flex flex-column'>
                            Dia
                            <select className='form-select form-select-sm' value={diaNascPadrinho} onChange={handleDiaNascPadrinhoChange} disabled={formWasSubmited}>
                              {obterDiasDoMes(mesNasc).map((dia) => (
                                <option key={dia} value={dia}>{dia}</option>
                              ))}
                            </select>
                          </label>
                          <label className='d-flex flex-column'>
                            Mês
                            <select className='form-select form-select-sm' value={mesNascPadrinho} onChange={handleMesNascPadrinhoChange} disabled={formWasSubmited}>
                              <option value={1}>Janeiro</option>
                              <option value={2}>Fevereiro</option>
                              <option value={3}>Março</option>
                              <option value={4}>Abril</option>
                              <option value={5}>Maio</option>
                              <option value={6}>Junho</option>
                              <option value={7}>Julho</option>
                              <option value={8}>Agosto</option>
                              <option value={9}>Setembro</option>
                              <option value={10}>Outubro</option>
                              <option value={11}>Novembro</option>
                              <option value={12}>Dezembro</option>
                            </select>
                          </label>
                          <label className='d-flex flex-column'>
                            Ano
                            <select className='form-select form-select-sm' value={anoNascPadrinho} onChange={handleAnoNascPadrinhoChange} disabled={formWasSubmited}>
                              {obterAnos().map((ano) => (
                                <option key={ano} value={ano}>{ano}</option>
                              ))}
                            </select>
                          </label>
                        </div>
                        {/* <Form.Control
                        type="date"
                        required
                        value={padrinhoDataNascimento}
                        onChange={(e) => setPadrinhoDataNascimento(e.target.value)}
                        disabled={formWasSubmited}
                      /> */}
                        <Form.Control.Feedback type="invalid">
                          Informe a data de nascimento do padrinho.
                        </Form.Control.Feedback>
                      </FormGroup>
                      <FormGroup className='mb-2'>
                        <Form.Label>Estado Civil</Form.Label>
                        <Form.Select
                          required={padrinhoNome?.length > 0}
                          value={padrinhoEstadoCivil}
                          onChange={(e) => setPadrinhoEstadoCivil(e.target.value)}
                          disabled={formWasSubmited}
                        >
                          <option value="">Selecione o estado civil</option>
                          <option value="0">Casado(a) no civil e religioso (Igreja católica)</option>
                          <option value="1">Casado(a) no civil e religioso (Outra igreja)</option>
                          <option value="2">Casado(a) só no civil</option>
                          <option value="3">Vivem juntos</option>
                          <option value="4">Solteiro(a)</option>
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">
                          Informe o estado civil do padrinho.
                        </Form.Control.Feedback>
                      </FormGroup>

                      <FormGroup className='mb-2'>
                        <Form.Label>É crismado?</Form.Label>
                        <Form.Select
                          required={padrinhoNome?.length > 0}
                          value={padrinhoCrismado}
                          onChange={(e) => setPadrinhoCrismado(e.target.value)}
                          disabled={formWasSubmited}
                        >
                          <option value="">Selecione uma opção</option>
                          <option value="Sim">Sim</option>
                          <option value="Não">Não</option>
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">
                          Informe se o padrinho é crismado ou não.
                        </Form.Control.Feedback>
                      </FormGroup>

                      {padrinhoCrismado == "Sim" && (
                        <Form.Group controlId="validationPadrinhoDocumentoCrismaBase64">
                          <Form.Label className="m-0"><FontAwesomeIcon icon={faFile} /> Documento de crisma</Form.Label>
                          <Form.Control
                            type="file" size="md"
                            disabled={formWasSubmited}
                            // required={padrinhoCrismado == "Sim"}
                            onChange={handleFileChangeDocumentoPadrinhoCrismaBase64}
                          />
                          <Form.Control.Feedback type="invalid">
                            Por favor, selecione um arquivo válido.
                          </Form.Control.Feedback>

                          {padrinhoDocumentoCrismaBase64 && (
                            <Button className='mt-1' size='sm' onClick={() => handleOpenModalVerDocumento(padrinhoDocumentoCrismaBase64)}>
                              <FontAwesomeIcon icon={faImage} /> Ver documento
                            </Button>
                          )}
                        </Form.Group>
                      )}
                    </>}
                </Col>
              </Row>

              {!formWasSubmited && (
                <Col md={4} className="d-flex my-2">
                  <Button type="submit" className='w-100 w-md-auto mt-3' id='btnCadastrar' disabled={requesting}>
                    {requesting ? (<Spinner animation="border" size="sm" />) : (<FontAwesomeIcon icon={faEnvelopeOpenText} />)}
                    &nbsp; {candidatoEditar ? 'Salvar' : 'Cadastrar'}
                  </Button>
                </Col>
              )}
            </Col>
          </Row>
        </Form>
      )}


      <ModalVerDocumento
        showModal={showModalVerDocumento}
        onClose={handleCloseModal}
        image={imagemVisualizar}
      />

      {(!periodoInscricaoEmAberto && !candidatoEditar?.id) && (
        <Alert variant="light">
          <Alert.Heading className='text-danger text-center'>Olá, querido crismando!</Alert.Heading>
          <hr />

          {ultimoPeriodoInscricao?.inscricao == null && (
            <h6 className="mb-0">
              Período de inscrições não iniciado.
            </h6>
          )}

          {ultimoPeriodoInscricao?.inscricao != null && (
            <h6 className="mb-0">
              Período de inscrições encerrado em {formatarData(ultimoPeriodoInscricao?.inscricao?.dataFim)}.
            </h6>
          )}


          <span className='mt-3 d-block border p-3 rounded-3'>
            <span className='d-block '>Em caso de dúvida entrar em contato com:</span>
            <span>Verônica</span>: <a className='me-2 text-primary text-decoration-none' target='_blank' href={linkUtil.gerarLinkWhatsappDuvidas('21988350766', idMatricula)}><FontAwesomeIcon icon={faWhatsapp} /> (21) 98835-0766</a>
            <br></br>
            <span> Cidinho</span>: <a target='_blank' className='mx-1 text-primary text-decoration-none' href={linkUtil.gerarLinkWhatsappDuvidas('21988350767)', idMatricula)}><FontAwesomeIcon icon={faWhatsapp} /> (21) 98835-0767</a>
          </span>
        </Alert>
      )}
    </>
  );

}

export default PageCadastro;
