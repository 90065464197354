import React, { useContext, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown'; // Importe o NavDropdown
import LogoImage from '../../resources/img/logo.jpg';
import { faCalendarDays, faChurch, faDownload, faFileInvoice, faHouse, faMoneyBillTrendUp, faRightFromBracket, faUsers } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { UserContext } from '../../Context/UserContext';
import { faUser } from '@fortawesome/free-solid-svg-icons/faUser';
import { Col, Row } from 'react-bootstrap';

function NavPrincipal() {
  const { user, logout } = useContext(UserContext); // Obtenha o usuário do contexto
  const [dropdownOpen, setDropdownOpen] = useState(false); // State para controlar se o dropdown está aberto

  const isCadastro = window.location.pathname.includes('cadastro');

  const handleDropdownToggle = () => {
    setDropdownOpen(!dropdownOpen); // Inverte o estado do dropdown
  };

  const handleLogout = () => {
    logout(); // Chama a função de logout ao clicar em "Sair"
    setDropdownOpen(false); // Fecha o dropdown após o logout
  };
  return (
    <>
      <Navbar expand="lg" className="bg-body-tertiary">
        <Container>
          <Navbar.Brand href={isCadastro ? '/cadastro' : '/'}>
            <img
              src={LogoImage}
              width="50"
              height="50"
              className="d-inline-block align-top"
              alt="Logo"
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" className={isCadastro ? 'd-none' : ''} />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className={`me-auto ${isCadastro ? 'd-none' : ''}`}>
              {user && (
                <>
                  <Nav.Link href="/"><FontAwesomeIcon icon={faHouse} /> Início</Nav.Link>
                  {user.role == 'adm' && (
                    <>
                      <Nav.Link href="/admin/inscricoes"><FontAwesomeIcon icon={faCalendarDays} /> Inscrições</Nav.Link>
                      <Nav.Link href="/admin/comunidades"><FontAwesomeIcon icon={faChurch} /> Comunidades</Nav.Link>
                      <Nav.Link href="/admin/financeiro"><FontAwesomeIcon icon={faMoneyBillTrendUp} /> Financeiro</Nav.Link>
                      <Nav.Link href="/admin/catequistas"><FontAwesomeIcon icon={faUsers} /> Catequistas</Nav.Link>
                    </>
                  )}
                  <Nav.Link href="/admin/downloads"><FontAwesomeIcon icon={faDownload} /> Downloads</Nav.Link>
                  {user.role == 'adm' && (
                    <Nav.Link href="/admin/relatorios"><FontAwesomeIcon icon={faFileInvoice} /> Relatórios</Nav.Link>
                  )}
                </>
              )}
            </Nav>
            {(user && !isCadastro) && (
              <Nav>
                <NavDropdown
                  title={(
                    <>
                      <FontAwesomeIcon icon={faUser} className='me-1' />
                      {user.userName}
                    </>)} // O título do dropdown será o nome do usuário
                  id="basic-nav-dropdown"
                  show={dropdownOpen} // Passa o estado do dropdown para controlar sua exibição
                  onToggle={handleDropdownToggle} // Define a função de toggle para o dropdown
                >
                  {/* Aqui você pode adicionar mais opções para o dropdown se necessário */}
                  <NavDropdown.Item onClick={handleDropdownToggle}><Nav.Link href="/admin/perfil" onClick={() => window.location.href = '/admin/perfil'}>Perfil</Nav.Link></NavDropdown.Item>
                  {/* <NavDropdown.Item onClick={handleDropdownToggle}>Configurações</NavDropdown.Item> */}
                  <NavDropdown.Divider />
                  <NavDropdown.Item className='text-danger' onClick={handleLogout}><FontAwesomeIcon icon={faRightFromBracket} /> Sair</NavDropdown.Item>
                </NavDropdown>
              </Nav>
            )}
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Row className="bg-body-tertiary shadow shadow-sm">
        {(user && user?.comunidade) && (
          <Col className='d-flex justify-content-center'>
            <h6 className='d-none d-md-inline'><FontAwesomeIcon icon={faChurch} /> {user?.comunidade}</h6>
            <span className='d-inline d-md-none'><FontAwesomeIcon icon={faChurch} /> {user?.comunidade}</span>
          </Col>
        )}
      </Row>
    </>
  );
}

export default NavPrincipal;
