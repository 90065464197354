import React, { useContext, useEffect, useState } from 'react';
import { Row, Col, Form, Pagination, Spinner, Button } from 'react-bootstrap';
import CardCandidato from '../../components/Card/CardCandidato';
import ModalDeletarCandidato from '../../components/Modal/Candidato/ModalDeletarCandidato';
import { candidatoService } from '../../services/candidatoService';
import TableCandidatos from '../../components/Table/TableCandidatos';
import ModalEditarCandidato from '../../components/Modal/Candidato/ModalEditarCandidato';
import { inscricaoService } from '../../services/periodoInscricaoService';
import { formatarData, getDataMenosDias } from '../../Util/DataUtil';
import { relatorio } from '../../Util/relatorioUtil';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint } from '@fortawesome/free-solid-svg-icons';

function Admin() {
  const [pageListCandidato, setPageListCandidato] = useState({});
  const [idPeriodoInscricao, setIdPeriodoInscricao] = useState(null);
  const [pageParams, setPageParams] = useState({ termoBusca: '', numeroPagina: 1 });
  const [candidatoToDelete, setCandidatoToDelete] = useState(null);
  const [candidatoToEdit, setCandidatoToEdit] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [pageListPeriodoInscricao, setPageListPeriodoInscricao] = useState({});

  const imprimir = () =>{
    setPageParams({...pageParams, tamanhoPagina: 1500});
    setTimeout(() => {
      relatorio.candidatos();
    }, 1500);
  }

  const handleEditCandidate = (candidato) => {
    setCandidatoToEdit(candidato);
    setShowEditModal(true);
  }

  const handleChangeIdPeriodoInscricao = (e) => {
    const selectedValue = e.target.value;
    setIdPeriodoInscricao(selectedValue);
  };

  const handleDeleteCandidate = (candidato) => {
    setCandidatoToDelete(candidato);
    setShowDeleteModal(true);
  };

  const handleConfirmDelete = (candidato) => {
    candidatoService.deleteData(candidato?.id ?? 0).then(() => {
      fetchCandidatos();
    });
    setShowDeleteModal(false);
  };

  const handleCancelDelete = () => {
    setShowDeleteModal(false);
  };

  useEffect(() => {
    fetchCandidatos(idPeriodoInscricao);
  }, [pageParams, idPeriodoInscricao]);

  useEffect(() => {
    inscricaoService.get({
      dataInicial: getDataMenosDias(18250), // Defina a data inicial como 50 anos atrás
      dataFinal: getDataMenosDias(-18250)// Defina a data inicial como 50 anos a frente
    }).then(resp => {
      setPageListPeriodoInscricao(resp);
    });
  }, []);

  const handleSearchChange = (event) => {
    setPageParams({ ...pageParams, termoBusca: event.target.value });
  };

  const fetchCandidatos = () => {
    setLoading(true);
    try {
      const idPeriodo = idPeriodoInscricao == 'Atual' ? null : idPeriodoInscricao;
      candidatoService.get(pageParams, idPeriodo).then(response => {
        setPageListCandidato(response);
        setLoading(false);
      });
    } catch (error) {
      console.error('Error fetching candidates:', error);
    }
  };

  const handlePaginationClick = (page) => {
    if (page > pageListCandidato?.totalPaginas ?? 0) return;
    setPageParams({ ...pageParams, numeroPagina: page });
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const renderPaginationItems = () => {
    const totalPages = pageListCandidato?.totalPaginas ?? 0;
    const currentPage = pageParams.numeroPagina;
    const displayPages = 6;
    const items = [];

    let startPage = Math.max(1, currentPage - Math.floor(displayPages / 2));
    let endPage = Math.min(totalPages, startPage + displayPages - 1);

    if (totalPages <= displayPages) {
      startPage = 1;
      endPage = totalPages;
    } else {
      if (endPage - startPage < displayPages - 1) {
        startPage = endPage - displayPages + 1;
      }
    }

    for (let number = startPage; number <= endPage; number++) {
      items.push(
        <Pagination.Item
          key={number}
          active={number === currentPage}
          onClick={() => handlePaginationClick(number)}
        >
          {number}
        </Pagination.Item>
      );
    }

    return items;
  };

  return (
    <div className={loading ? 'vh-100' : ''}>
      <Row>
        <Col sm={12} md={12}>
          {/* Main content */}
          <h2>Candidatos</h2>
          <Form.Control
            size="md"
            type="text"
            className='pesquisa mb-2'
            placeholder="Buscar por matrícula, nome, telefone..."
            value={pageParams.termoBusca}
            onChange={handleSearchChange}
          />
          <small className='d-block text-muted text-end'>Exibindo <strong>{pageListCandidato?.itens?.length ?? 0}</strong> de um total de <strong>{pageListCandidato?.qtdResultados ?? 0}</strong> resultados</small>
        </Col>
        <Col className='mb-3'>
          <strong>Período de inscrição</strong>
          <Form.Select aria-label="Default select example" value={idPeriodoInscricao} onChange={handleChangeIdPeriodoInscricao}>
            <option value={null}>Atual</option>
            <option value={0}>Todos</option>
            {pageListPeriodoInscricao?.itens?.map((p) => (
              <option key={p.id} value={p.id}>{`${formatarData(p.dataInicio)} - ${formatarData(p.dataFim)}`}</option>
            ))}
          </Form.Select>
          
           <div className='mt-2 d-flex justify-content-end'>
            <Button onClick={imprimir} variant='secondary'><FontAwesomeIcon icon={faPrint}/> Imprimir</Button>
          </div>
        </Col>
      </Row>

      {loading ? (
        <div className='d-flex align-items-center justify-content-center p-3'>
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>

      ) :
        (
          <>
            <Row className='d-none d-sm-flex'>
              <TableCandidatos candidatos={pageListCandidato?.itens} onTrashClick={handleDeleteCandidate} onDeleteClick={handleDeleteCandidate} onEditClick={handleEditCandidate} />
            </Row>
            <Row className='d-flex d-sm-none'>
              {pageListCandidato?.itens && pageListCandidato?.itens.map((candidato) => {
                return <CardCandidato key={candidato?.id} candidato={candidato} onTrashClick={handleDeleteCandidate} onDeleteClick={handleDeleteCandidate} onEditClick={handleEditCandidate} />;
              })}
            </Row>
          </>
        )}

      {!loading && (
        <Row>
          <Col>
            <Pagination className='d-flex justify-content-center pagination-sm'>
              <Pagination.First onClick={() => handlePaginationClick(1)} />
              <Pagination.Prev onClick={() => handlePaginationClick(pageParams.numeroPagina - 1)} />
              {renderPaginationItems()}
              <Pagination.Next onClick={() => handlePaginationClick(pageParams.numeroPagina + 1)} />
              <Pagination.Last onClick={() => handlePaginationClick(pageListCandidato?.totalPaginas ?? 0)} />
            </Pagination>
          </Col>
        </Row>
      )}

      {!loading && pageListCandidato?.itens?.length == 0 && (
        <h5 className='d-flex justify-content-center'>Nenhum resultado encontrado</h5>
      )}

      <ModalDeletarCandidato show={showDeleteModal}
        onHide={handleCancelDelete}
        candidato={candidatoToDelete}
        onConfirmDelete={handleConfirmDelete} />

      <ModalEditarCandidato
        show={showEditModal}
        onHide={() => setShowEditModal(false)}
        candidato={candidatoToEdit}
      />

    </div>
  );
}

export default Admin;
