import { toast } from "react-toastify";
import api from "./api/api"
import { ApiErrorUtil } from "../Util/ApiErrorUtil";
import { createQueryParams } from "../Util/PageParamsUtil";

const post = async (dataPost) => {
    try {
        const { data } = await api.post("/arquivoDownload", dataPost);
        toast.success(data);
        return data;
    } catch (error) {
        toast.error(error.message || "Erro desconhecido");
    }
}

const get = async (pageParams) => {
    try {
        const { data } = await api.get("/arquivoDownload" + createQueryParams(pageParams));
        return data;
    } catch (error) {
        toast.error(error.message || "Erro desconhecido");
    }
}

const deleteData = async (id) => {
    try {
        const { data } = await api.delete(`/arquivoDownload?id=${id}`);
        toast.success(data);
    } catch (error) {
        ApiErrorUtil.showError(error);
    }
}

const downloadArquivo = (id) => {
    window.open(api.getUri() + '/arquivoDownload/download?' + 'id=' + id, '_blank');
}

export const arquivosDownloadService = {
    get,
    deleteData,
    post,
    downloadArquivo
}