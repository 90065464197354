import React, { useEffect, useState } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import { faArrowLeft, faCalendarAlt, faCircleCheck, faContactCard, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { candidatoService } from './../../../services/candidatoService';
import { formatarData, formatarDataParaInput } from '../../../Util/DataUtil';
import { registroPresencaService } from '../../../services/registroPresencaService';
import { toast } from 'react-toastify';

const ModalRegistroPresenca = ({ show, onHide, candidatoId }) => {
    const [data, setData] = useState(formatarDataParaInput(new Date(), true));
    const [presente, setPresente] = useState('Sim');
    const [observacao, setObservacao] = useState('');
    const [candidato, setCandidato] = useState(null);
    const [isSaving, setIsSaving] = useState(false);

    const handleSalvarClick = async () => {
        if (!data) {
            toast.error("Por favor, preencha a data antes de salvar.");
            return;
        }

        // Verifica se a data selecionada é futura
        const dataSelecionada = new Date(data);
        const dataAtual = new Date();
        if (dataSelecionada > dataAtual) {
            toast.error("A data selecionada não pode ser uma data futura.");
            return;
        }

        setIsSaving(true); // Desabilita o botão Salvar
        try {
            const registroPresenca = {
                data: data,
                presente: presente === 'Sim',
                observacao: observacao || null,
                candidatoId: candidatoId
            };
            await registroPresencaService.post(registroPresenca);
            onHide();
            toast.success("Registro salvo com sucesso");
        } catch (error) {
            console.error('Erro ao salvar:', error);
        } finally {
            setIsSaving(false); // Habilita o botão Salvar
        }
    };

    useEffect(() => {
        if (candidatoId > 0) {
            candidatoService.getId(candidatoId).then(resp => {
                if (resp) {
                    setCandidato(resp);
                }
            });
        }
    }, [candidatoId]);

    return (
        <Modal size='md' show={show} onHide={onHide} centered>
            <Modal.Header closeButton>
                <h6><FontAwesomeIcon icon={faContactCard} /> Registro de Presença</h6>
            </Modal.Header>

            <Modal.Body>
                <span style={{ fontSize: '20px' }}><FontAwesomeIcon icon={faUser} /> {candidato?.nome}</span>
                <div className='my-1'></div>
                <span ><FontAwesomeIcon icon={faCalendarAlt} style={{ fontSize: '20px' }} /> {formatarData(candidato?.dataNascimento)}</span>
                <div className='my-1 mb-3'></div>
                <Form>
                    <Form.Group controlId="formDate">
                        <Form.Label>Data:</Form.Label>
                        <Form.Control
                            type="datetime-local"
                            value={data}
                            onChange={(e) => setData(e.target.value)}
                        />
                        {!data && <div className="text-danger">Por favor, preencha a data.</div>}
                    </Form.Group>

                    <Form.Group controlId="formPresente" className='mt-2'>
                        <Form.Label>Presente:</Form.Label>
                        <Form.Control
                            as="select"
                            value={presente}
                            onChange={(e) => setPresente(e.target.value)}
                        >
                            <option value="Sim">Sim</option>
                            <option value="Não">Não</option>
                        </Form.Control>
                    </Form.Group>

                    <Form.Group controlId="formObservacao">
                        <Form.Label>Observação:</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={3}
                            value={observacao}
                            placeholder='Alguma obervação para este registro?'
                            onChange={(e) => setObservacao(e.target.value)}
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>

            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}><FontAwesomeIcon icon={faArrowLeft} /> Fechar</Button>
                <Button variant="success" onClick={handleSalvarClick} disabled={isSaving}><FontAwesomeIcon icon={faCircleCheck} /> Salvar</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ModalRegistroPresenca;
