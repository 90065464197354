import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import PageCadastroComunidade from '../../../pages/Admin/Comunidade/PageCadastroComunidade';
import { faArrowLeft, faCircleCheck, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { comunidadeService } from './../../../services/comunidadeService';

const ModalEditarComunidade = ({ show, onHide, comunidade }) => {
    const [comunidadeAtual, setComunidadeAtual] = useState(comunidade || {});

    const handleSalvarClick = async () => {
        try {
            await comunidadeService.post(comunidadeAtual);
            onHide();
        } catch (error) {
            console.error('Erro ao salvar:', error);
        }
    };

    const handleComunidadeChange = (novaComunidade) => {
        setComunidadeAtual(novaComunidade);
    };

    return (
        <Modal size='md' show={show} onHide={onHide}
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title><FontAwesomeIcon icon={faUser} /> Dados da comunidade</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <PageCadastroComunidade
                    comunidade={comunidade}
                    setComunidade={handleComunidadeChange} // Passa a função para atualizar a comunidade
                />
            </Modal.Body>

            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}><FontAwesomeIcon icon={faArrowLeft} /> Voltar</Button>
                <Button variant="success" onClick={handleSalvarClick}><FontAwesomeIcon icon={faCircleCheck} /> Salvar</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ModalEditarComunidade;
