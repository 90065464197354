import React, { useEffect, useState } from 'react';
import { Table, Button, Row, Col, Pagination, Modal, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDotCircle, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { formatarData, formatarDataParaInput, getCurrentDate, getDataMenosDias } from '../../../Util/DataUtil';
import ModalDeletarInscricao from '../../../components/Modal/Inscricao/ModalDeletarInscricao';
import { inscricaoService } from '../../../services/periodoInscricaoService';
import { toast } from 'react-toastify';

function PageInscricoes() {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedTransacaoToDelete, setSelectedInscricaoToDelete] = useState(null);
  const [pageListInscricao, setPageListInscricao] = useState({});
  const [inscricaoEmAberto, setInscricaoEmAberto] = useState({});
  const [pageParams, setPageParams] = useState({ termoBusca: '', numeroPagina: 1 });
  const [dataInicial, setDataInicial] = useState(() => formatarDataParaInput(getDataMenosDias(365)));
  const [dataFinal, setDataFinal] = useState(() => formatarDataParaInput(getCurrentDate()));
  const [showModal, setShowModal] = useState(false);
  const [selectedInscricao, setSelectedInscricao] = useState({
    dataInicio: formatarDataParaInput(new Date()),
    dataFim: formatarDataParaInput(getDataMenosDias(-30))
  });
  const [selectedInscricaoIndex, setSelectedInscricaoIndex] = useState(null);

  const validarDatas = (dataInicio, dataFim) => {
    const dataInicioDate = new Date(dataInicio);
    const dataFimDate = new Date(dataFim);

    if (isNaN(dataInicioDate.getTime()) || isNaN(dataFimDate.getTime())) {
      toast.error('Por favor, insira datas válidas.');
      return false;
    }

    if (dataInicioDate > dataFimDate) {
      toast.error('A data inicial não pode ser posterior à data final.');
      return false;
    }

    return true;
  };


  const onDataChange = (newDataInicial, newDataFinal) => {
    setPageParams({
      ...pageParams,
      dataInicial: newDataInicial,
      dataFinal: newDataFinal,
      numeroPagina: 1
    });
  };

  const handleEditClick = (inscricao, index) => {
    // if(dataEhMaiorQueHoje(inscricao))

    setSelectedInscricao(
      {
        id: inscricao?.id,
        dataInicio: formatarDataParaInput(inscricao?.dataInicio),
        dataFim: formatarDataParaInput(inscricao?.dataFim)
      }
    );
    setSelectedInscricaoIndex(index);
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
    setSelectedInscricao({ data: '', descricao: '', valor: '', tipo: -1 });
    setSelectedInscricaoIndex(null);
  };

  useEffect(() => {
    // setSelectedInscricao(
    //   {
    //     dataInicio: formatarDataParaInput(selectedInscricao.dataInicio),
    //     dataFim: formatarDataParaInput(selectedInscricao.dataFim)
    //   }
    // )
  }, [selectedInscricao])

  const handleModalSave = async () => {
    try {
      if (!validarDatas(selectedInscricao?.dataInicio, selectedInscricao?.dataFim)) return;

      inscricaoService.post(selectedInscricao)
        .then(resp => {
          onDataChange(dataInicial, dataFinal);
          setShowModal(false);
        });
    } catch (error) {
      console.error('Erro ao salvar:', error);
    }
  };

  useEffect(() => {
    // Aqui você pode buscar os dados da inscrição selecionada no modal de edição, se necessário
  }, [showModal]);

  const handleDataInicialChange = (e) => {
    const value = e.target.value;
    setDataInicial(value);
  };

  const handleDataFinalChange = (e) => {
    const value = e.target.value;
    setDataFinal(value);
  };

  const handleFiltrarClick = () => {
    onDataChange(dataInicial, dataFinal);
  };

  const handleNovaTransacaoClick = () => {
    if (!!inscricaoEmAberto?.id) {
      toast.warning("Já existe um período de inscrição em andamento!");
      return;
    }

    setSelectedInscricao({
      dataInicio: formatarDataParaInput(new Date()),
      dataFim: formatarDataParaInput(getDataMenosDias(-30))
    });
    setShowModal(true); // Abrir o modal ao clicar em "Nova"
  };

  const handleDeleteClick = (transacao) => {
    setSelectedInscricaoToDelete(transacao);
    setShowDeleteModal(true);
  };

  const handleConfirmDelete = async () => {
    try {
      await inscricaoService.deleteData(selectedTransacaoToDelete.id);
      onDataChange(dataInicial, dataFinal);
      setShowDeleteModal(false);
    } catch (error) {
      console.error("Erro ao excluir transação:", error);
    }
  };

  useEffect(() => {
    inscricaoService.obterEmAberto().then(resp => {
      setInscricaoEmAberto(resp);
    });

    inscricaoService.get(pageParams).then(resp => {
      setPageListInscricao(resp);
    });
  }, [pageParams]);

  const handlePaginationClick = (page) => {
    if (page > pageListInscricao?.totalPaginas ?? 0) return;
    setPageParams({ ...pageParams, numeroPagina: page });
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const renderPaginationItems = () => {
    const totalPages = pageListInscricao?.totalPaginas ?? 0;
    const currentPage = pageParams.numeroPagina;
    const displayPages = 6;
    const items = [];

    let startPage = Math.max(1, currentPage - Math.floor(displayPages / 2));
    let endPage = Math.min(totalPages, startPage + displayPages - 1);

    if (totalPages <= displayPages) {
      startPage = 1;
      endPage = totalPages;
    } else {
      if (endPage - startPage < displayPages - 1) {
        startPage = endPage - displayPages + 1;
      }
    }

    for (let number = startPage; number <= endPage; number++) {
      items.push(
        <Pagination.Item
          key={number}
          active={number === currentPage}
          onClick={() => handlePaginationClick(number)}
        >
          {number}
        </Pagination.Item>
      );
    }

    return items;
  };

  return (
    <>
      <Row className='d-flex justify-content-center'>
        <Col md={12}>
          <Row className='d-flex justify-content-md-end'>
            <Col className='col-6 col-md-3'>
              <label htmlFor="" className='w-100'>
                Data inicial
                <input className='form-control' type='date' value={dataInicial} onChange={handleDataInicialChange} />
              </label>
            </Col>
            <Col className='col-6 col-md-3'>
              <label htmlFor="" className='w-100'>
                Data final
                <input className='form-control' type='date' value={dataFinal} onChange={handleDataFinalChange} />
              </label>
            </Col>
            <Col md={2} lg={1}>
              <Button className='my-2 my-md-4' onClick={handleFiltrarClick}>Filtrar</Button>
            </Col>
          </Row>
        </Col>

        <Row className='d-flex justify-content-center'>
          <Col md={12}>
            <small className='d-block text-muted text-end'>Exibindo <strong>{pageListInscricao?.itens?.length ?? 0}</strong> de um total de <strong>{pageListInscricao?.qtdResultados ?? 0}</strong> resultados</small>
          </Col>
        </Row>

        <Col md={12} className='d-flex justify-content-end'>

          <Col className='d-flex align-items-center'>
            <h5>Período de inscrições</h5>
          </Col>
          <Button className='my-2' onClick={handleNovaTransacaoClick}><FontAwesomeIcon icon={faPlus} /> Nova</Button>
        </Col>

        <Col>
          <Table striped hover>
            <thead>
              <tr>
                <th>Data início</th>
                <th>Data fim</th>
                <th>Status</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {pageListInscricao?.itens?.map((inscricao, idx) => (
                <tr key={inscricao.id}>
                  <td onClick={() => handleEditClick(inscricao, idx)}>{formatarData(inscricao?.dataInicio)}</td>
                  <td onClick={() => handleEditClick(inscricao, idx)}>{formatarData(inscricao?.dataFim)}</td>
                  <td onClick={() => handleEditClick(inscricao, idx)}><FontAwesomeIcon className={!inscricao?.finalizado ? 'text-success' : 'text-danger'} icon={faDotCircle} /> </td>
                  <td>
                    {idx === pageListInscricao?.itens?.length - 1 && inscricao.dataFim != null && (
                      <Button variant='outline-danger' onClick={() => handleDeleteClick(inscricao)}><FontAwesomeIcon icon={faTrash} /></Button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>

        {pageListInscricao?.itens?.length === 0 && (
          <h5 className='d-flex justify-content-center'>Nenhum resultado encontrado</h5>
        )}

        <ModalDeletarInscricao
          show={showDeleteModal}
          onHide={() => setShowDeleteModal(false)}
          transacao={selectedTransacaoToDelete}
          onConfirmDelete={handleConfirmDelete}
        />

        <Modal show={showModal} onHide={handleModalClose}>
          <Modal.Header closeButton>
            <Modal.Title>Período de inscrição</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group as={Row} controlId="formHorizontalDataInicio">
                <Form.Label column sm={3}>
                  Data Início
                </Form.Label>
                <Col sm={9}>
                  <Form.Control
                    type="date"
                    value={selectedInscricao?.dataInicio}
                    onChange={(e) =>
                      setSelectedInscricao({
                        ...selectedInscricao,
                        dataInicio: e.target.value,
                      })
                    }
                  />
                </Col>
              </Form.Group>

              <Form.Group as={Row} controlId="formHorizontalDataFim" className="mt-2">
                <Form.Label column sm={3}>
                  Data Fim
                </Form.Label>
                <Col sm={9}>
                  <Form.Control
                    type="date"
                    value={selectedInscricao?.dataFim}
                    onChange={(e) =>
                      setSelectedInscricao({
                        ...selectedInscricao,
                        dataFim: e.target.value,
                      })
                    }
                  />
                </Col>
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleModalClose}>
              Fechar
            </Button>
            <Button variant="primary" onClick={handleModalSave}>
              Salvar
            </Button>
          </Modal.Footer>
        </Modal>
      </Row>
      <Row>
        <Col>
          <Pagination className='d-flex justify-content-center pagination-sm'>
            <Pagination.First onClick={() => handlePaginationClick(1)} />
            <Pagination.Prev onClick={() => handlePaginationClick(pageParams.numeroPagina - 1)} />
            {renderPaginationItems()}
            <Pagination.Next onClick={() => handlePaginationClick(pageParams.numeroPagina + 1)} />
            <Pagination.Last onClick={() => handlePaginationClick(pageListInscricao?.totalPaginas ?? 0)} />
          </Pagination>
        </Col>
      </Row>
    </>
  );
}

export default PageInscricoes;
