import { faArrowDown, faArrowUp, faCalendar, faCalendarAlt, faCashRegister, faMagnifyingGlassChart } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Card, Col, Row } from 'react-bootstrap'

const CardsResumoFinanceiro = ({ resumoFinanceiro }) => {
    return (
        <Row className='d-flex justify-content-evenly'>
            <Col md={3} className='mb-2 mb-md-0'>
                <Card className='text-success border-success'>
                    <Card.Body className='d-flex justify-content-between align-items-center'>
                        <smal>
                            <FontAwesomeIcon icon={faArrowDown} /> Entradas
                        </smal>
                        <h5 className='text-end m-0'><strong>R$ {(resumoFinanceiro?.valorTotalEntradasDoPeriodo ?? 0)?.toFixed(2).replace('.', ',')}</strong></h5>
                    </Card.Body>
                </Card>
            </Col>
            <Col md={3} className='mb-2 mb-md-0'>
                <Card className='text-danger border-danger'>
                    <Card.Body className='d-flex justify-content-between align-items-center'>
                        <span>
                            <FontAwesomeIcon icon={faArrowUp} /> Saídas
                        </span>
                        <h5 className='text-end m-0'><strong>R$ {(resumoFinanceiro?.valorTotalSaidasDoPeriodo ?? 0)?.toFixed(2).replace('.', ',')}</strong></h5>
                    </Card.Body>
                </Card>
            </Col>

            <Col md={3} className='mb-2 mb-md-0'>
                <Card className='text-info border-info'>
                    <Card.Body className='d-flex justify-content-between align-items-center'>
                        <span>
                            <FontAwesomeIcon icon={faCalendarAlt} /> Saldo per.
                        </span>
                        <h5 className='text-end m-0'><strong>R$ {(resumoFinanceiro?.saldoDoPeriodo ?? 0)?.toFixed(2).replace('.', ',')}</strong></h5>
                    </Card.Body>
                </Card>
            </Col>

            <Col md={3} className='mb-2 mb-md-0'>
                <Card className='text-primary border-primary'>
                    <Card.Body className='d-flex justify-content-between align-items-center'>
                        <span>
                            <FontAwesomeIcon icon={faCashRegister} /> Saldo atual
                        </span>
                        <h5 className='text-end m-0'><strong>R$ {(resumoFinanceiro?.saldoAtual ?? 0)?.toFixed(2).replace('.', ',')}</strong></h5>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    )
}

export default CardsResumoFinanceiro