import { toast } from "react-toastify";
import api from "./api/api"
import { ApiErrorUtil } from "../Util/ApiErrorUtil";
import { createQueryParams } from "../Util/PageParamsUtil";

const post = async (dataPost) => {
    try {
        const { data } = await api.post("/periodoInscricao", dataPost);
        toast.success(data);
        return data;
    } catch (error) {
        toast.error(error.message || "Erro desconhecido");
    }
}

const get = async (pageParams) => {
    try {
        const { data } = await api.get("/periodoInscricao" + createQueryParams(pageParams));
        return data;
    } catch (error) {
        if(error?.response?.status == 401){
            toast.error("Acesso não autorizado")
        } else {
            toast.error(error.message || "Erro desconhecido");
        }
    }
}

const obterEmAberto = async () => {
    try {
        const { data } = await api.get("/periodoInscricao/get/emAberto");
        return data;
    } catch (error) {
        toast.error(error.message || "Erro desconhecido");
    }
}


const deleteData = async (id) => {
    try {
        const { data } = await api.delete(`/periodoInscricao?id=${id}`);
        toast.success(data);
    } catch (error) {
        ApiErrorUtil.showError(error);
    }
}

export const inscricaoService = {
    get,
    deleteData,
    post,
    obterEmAberto
}