import { faArrowLeft, faCircleCheck, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import PageCadastro from '../../../pages/Cadastro/PageCadastro';

const ModalEditarCandidato = ({ show, onHide, candidato }) => {
    return (
        <Modal size='xl' show={show} onHide={onHide}
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title><FontAwesomeIcon icon={faUser} /> Dados do candidato</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <PageCadastro candidatoEditar={candidato} />
            </Modal.Body>

            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}><FontAwesomeIcon icon={faArrowLeft} /> Voltar</Button>
                {/* <Button variant="success"><FontAwesomeIcon icon={faCircleCheck} onClick={() => setSalvando(true)} /> Salvar</Button> */}
            </Modal.Footer>
        </Modal>
    );
};

export default ModalEditarCandidato;
