import React from 'react';
import { Table, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { formatarData } from '../../Util/DataUtil';

function TableComunidades({ comunidades, onDeleteClick, onEditClick }) {
  return (
    <Table striped hover>
      <thead>
        <tr>
          <th>Nome</th>
          <th>Data Cadastro</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {comunidades?.map((comunidade) => (
          <tr key={comunidade.id}>
            <td onClick={() => onEditClick(comunidade)}>{comunidade.nome}</td>
            <td onClick={() => onEditClick(comunidade)}>{formatarData(comunidade.createdAt, true)}</td>
            <td>
              <Button variant='outline-danger' onClick={() => onDeleteClick(comunidade)}><FontAwesomeIcon icon={faTrash} /></Button>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}

export default TableComunidades;
