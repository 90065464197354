import React, { useEffect, useState } from 'react';
import { Row, Col, Form, Pagination, Spinner, Button } from 'react-bootstrap';
import TableComunidades from '../../../components/Table/TableComunidades';
import { comunidadeService } from '../../../services/comunidadeService';
import ModalEditarComunidade from '../../../components/Modal/Comunidade/ModalEditarComunidade';
import ModalDeletarComunidade from '../../../components/Modal/Comunidade/ModalDeletarComunidade';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

function PageComunidades() {
  const [pageListComunidade, setPageListComunidade] = useState({});
  const [pageParams, setPageParams] = useState({ termoBusca: '', numeroPagina: 1 });
  const [comunidadeToDelete, setComunidadeToDelete] = useState(null);
  const [comunidadeToEdit, setComunidadeToEdit] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [loading, setLoading] = useState(true);

  const handleEditComunidade = (comunidade) => {
    setComunidadeToEdit(comunidade);
    setShowEditModal(true);
  }

  const handleDeleteComunidade = (comunidade) => {
    setComunidadeToDelete(comunidade);
    setShowDeleteModal(true);
  };

  const handleConfirmDelete = (comunidade) => {
    comunidadeService.deleteData(comunidade?.id ?? 0).then(() => {
      fetchComunidades();
    });
    setShowDeleteModal(false);
  };

  const handleCancelDelete = () => {
    setShowDeleteModal(false);
  };

  useEffect(() => {
    fetchComunidades();
  }, [pageParams]);

  const handleSearchChange = (event) => {
    setPageParams({ ...pageParams, termoBusca: event.target.value });
  };

  const fetchComunidades = () => {
    setLoading(true);
    try {
      comunidadeService.get(pageParams).then(response => {
        setPageListComunidade(response);
        setLoading(false);
      });
    } catch (error) {
      console.error('Error fetching comunidades:', error);
    }
  };

  const handlePaginationClick = (page) => {
    if (page > pageListComunidade?.totalPaginas ?? 0) return;
    setPageParams({ ...pageParams, numeroPagina: page });
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const renderPaginationItems = () => {
    const totalPages = pageListComunidade?.totalPaginas ?? 0;
    const currentPage = pageParams.numeroPagina;
    const displayPages = 6;
    const items = [];

    let startPage = Math.max(1, currentPage - Math.floor(displayPages / 2));
    let endPage = Math.min(totalPages, startPage + displayPages - 1);

    if (totalPages <= displayPages) {
      startPage = 1;
      endPage = totalPages;
    } else {
      if (endPage - startPage < displayPages - 1) {
        startPage = endPage - displayPages + 1;
      }
    }

    for (let number = startPage; number <= endPage; number++) {
      items.push(
        <Pagination.Item
          key={number}
          active={number === currentPage}
          onClick={() => handlePaginationClick(number)}
        >
          {number}
        </Pagination.Item>
      );
    }

    return items;
  };

  useEffect(() => {
    if (!showEditModal) {
      fetchComunidades();
    }
  }, [showEditModal])

  return (
    <div className={loading ? 'vh-100' : ''}>
      <Row>
        <Col sm={12} md={12}>
          {/* Main content */}
          <h2>Comunidades</h2>
          <Form.Control
            size="md"
            type="text"
            className='mb-2'
            placeholder="Buscar"
            value={pageParams.termoBusca}
            onChange={handleSearchChange}
          />
          <small className='d-block text-muted text-end'>Exibindo <strong>{pageListComunidade?.itens?.length ?? 0}</strong> de um total de <strong>{pageListComunidade?.qtdResultados ?? 0}</strong> resultados</small>
        </Col>
      </Row>

      {loading ? (
        <div className='d-flex align-items-center justify-content-center p-3'>
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>

      ) :
        (
          <>
            <Row>
              <Col className='d-flex justify-content-end'>
                <Button onClick={() => { setShowEditModal(true); setComunidadeToEdit({ id: 0, nome: '' }) }}><FontAwesomeIcon icon={faPlus} /> Nova</Button>
              </Col>
              <TableComunidades comunidades={pageListComunidade?.itens} onTrashClick={handleDeleteComunidade} onDeleteClick={handleDeleteComunidade} onEditClick={handleEditComunidade} />
            </Row>
          </>
        )}

      {!loading && (
        <Row>
          <Col>
            <Pagination className='d-flex justify-content-center pagination-sm'>
              <Pagination.First onClick={() => handlePaginationClick(1)} />
              <Pagination.Prev onClick={() => handlePaginationClick(pageParams.numeroPagina - 1)} />
              {renderPaginationItems()}
              <Pagination.Next onClick={() => handlePaginationClick(pageParams.numeroPagina + 1)} />
              <Pagination.Last onClick={() => handlePaginationClick(pageListComunidade?.totalPaginas ?? 0)} />
            </Pagination>
          </Col>
        </Row>
      )}

      {!loading && pageListComunidade?.itens?.length == 0 && (
        <h5 className='d-flex justify-content-center'>Nenhum resultado encontrado</h5>
      )}

      <ModalDeletarComunidade
        show={showDeleteModal}
        onHide={handleCancelDelete}
        comunidade={comunidadeToDelete} // Aqui deve ser passado comunidadeToDelete
        onConfirmDelete={handleConfirmDelete}
      />

      <ModalEditarComunidade
        show={showEditModal}
        onHide={() => setShowEditModal(false)}
        comunidade={comunidadeToEdit}
      />

    </div>
  );
}

export default PageComunidades;
