import React, { useEffect, useState, useCallback } from 'react';
import { Col, Row, Form, Button, Table } from 'react-bootstrap';
import { comunidadeService } from '../../services/comunidadeService';
import { candidatoService } from '../../services/candidatoService';
import { relatorioService } from '../../services/relatorioService';
import { formatarDataParaInput, getDataMenosDias } from '../../Util/DataUtil';
import { inscricaoService } from '../../services/periodoInscricaoService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileInvoice, faPrint } from '@fortawesome/free-solid-svg-icons';

const RelatorioPresenca = () => {
  const today = new Date().toISOString().split('T')[0];

  const [dataInicio, setDataInicio] = useState(formatarDataParaInput(getDataMenosDias(30)));
  const [dataFim, setDataFim] = useState(today);
  const [comunidades, setComunidades] = useState([]);
  const [periodosInscricao, setPeriodosInscricao] = useState([]);
  const [candidatos, setCandidatos] = useState([]);
  const [comunidadeSelecionada, setComunidadeSelecionada] = useState(null);
  const [periodoInscricaoSelecionado, setPeriodoInscricaoSelecionado] = useState(null);
  const [candidatoSelecionado, setCandidatoSelecionado] = useState(null);
  const [dadosRelatorio, setDadosRelatorio] = useState([]);
  const [carregando, setCarregando] = useState(false);

  useEffect(() => {
    comunidadeService.get().then(resp => setComunidades(resp?.itens ?? []));
    inscricaoService.get().then(resp => setPeriodosInscricao(resp?.itens ?? []));
  }, []);

  useEffect(() => {
    if (comunidadeSelecionada && periodoInscricaoSelecionado) {
      setCandidatoSelecionado(0);
      candidatoService.getResumido(periodoInscricaoSelecionado, comunidadeSelecionada)
        .then(resp => setCandidatos(resp))
        .catch(() => setCandidatos([]));
    }
  }, [comunidadeSelecionada, periodoInscricaoSelecionado]);

  const handleDataInicioChange = useCallback((event) => {
    setDataInicio(event.target.value);
  }, []);

  const handleDataFimChange = useCallback((event) => {
    setDataFim(event.target.value);
  }, []);

  const handleComunidadeChange = useCallback((event) => {
    setComunidadeSelecionada(event.target.value);
  }, []);

  const handlePeriodoInscricaoChange = useCallback((event) => {
    setPeriodoInscricaoSelecionado(event.target.value);
  }, []);

  const handleCandidatoChange = useCallback((event) => {
    setCandidatoSelecionado(event.target.value);
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    setCarregando(true);

    const params = {
      periodoInscricaoId: periodoInscricaoSelecionado,
      comunidadeId: comunidadeSelecionada,
      candidatoId: candidatoSelecionado,
      dataInicio: formatarDataParaInput(dataInicio),
      dataFim: formatarDataParaInput(dataFim),
    };

    relatorioService.getPresenca(params).then((resp) => {
      setDadosRelatorio(resp ?? []);
      setCarregando(false);
    }).catch(() => {
      setCarregando(false);
    });
  };

  const handlePrint = () => {
    const printContent = document.getElementById('tabelaRelatorio').outerHTML;
    const newWindow = window.open('', '', 'width=800,height=600');
    newWindow.document.write(`
      <html>
        <head>
          <title>Relatório de Presença</title>
          <style>
            table {
              width: 100%;
              border-collapse: collapse;
            }
            th, td {
              border: 1px solid black;
              padding: 8px;
              text-align: left;
            }
          </style>
        </head>
        <body>
        <h2>Relatório de Presença</h2>
        <small>Gerado em ${new Date().toLocaleDateString() + ' às ' + new Date().toLocaleTimeString()}</small>
          ${printContent}
        </body>
      </html>
    `);
    newWindow.document.close();
    newWindow.print();
  };

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Row className="d-flex">
          <Col md={4} className="d-flex align-items-center">
            <Form.Group controlId="formDataInicio" className="me-2">
              <Form.Label>Data de Início</Form.Label>
              <Form.Control
                type="date"
                value={dataInicio}
                onChange={handleDataInicioChange}
              />
            </Form.Group>
            <Form.Group controlId="formDataFim">
              <Form.Label>Data de Fim</Form.Label>
              <Form.Control
                type="date"
                value={dataFim}
                onChange={handleDataFimChange}
              />
            </Form.Group>
          </Col>
          <Col md={8} className='d-flex align-items-center'>
            <Form.Group controlId="formPeriodoInscricao">
              <Form.Label>Período de Inscrição</Form.Label>
              <Form.Select
                value={periodoInscricaoSelecionado}
                onChange={handlePeriodoInscricaoChange}
              >
                <option value={0}>Atual</option>
                {periodosInscricao?.map((periodo) => (
                  <option key={periodo.id} value={periodo.id}>
                    {periodo.periodo}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            <Form.Group controlId="formComunidade" className='ms-2'>
              <Form.Label>Comunidade</Form.Label>
              <Form.Select
                value={comunidadeSelecionada}
                onChange={handleComunidadeChange}
              >
                <option value={0}>Todas</option>
                {comunidades?.map((comunidade) => (
                  <option key={comunidade.id} value={comunidade.id}>
                    {comunidade.nome}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            {(periodoInscricaoSelecionado ?? 0) > 0 && (comunidadeSelecionada ?? 0) > 0 && (
              <Form.Group controlId="formCandidato" className="ms-2">
                <Form.Label>Candidato</Form.Label>
                <Form.Select
                  value={candidatoSelecionado}
                  onChange={handleCandidatoChange}
                >
                  <option value={0}>Todos</option>
                  {candidatos?.map((candidato) => (
                    <option key={candidato.id} value={candidato.id}>
                      {candidato.nome}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            )}
          </Col>

          <Col className='d-flex justify-content-end mt-2'>
            <Button variant="primary" type="submit">
              <FontAwesomeIcon icon={faFileInvoice}/> Gerar Relatório
            </Button>

            {dadosRelatorio?.length > 0 && (
              <Button className='ms-2' variant="secondary" onClick={handlePrint}>
              <FontAwesomeIcon icon={faPrint}/> Imprimir
              </Button>
            )}
          </Col>
        </Row>
        <br />
        <Row>
          <Col>
            <h2>Relatório de Presença</h2>
            {carregando ? (
              <div className="d-flex justify-content-center">
                <div className="spinner-border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            ) : (
              <>
                {dadosRelatorio?.length > 0 ? (
                  <>
                    <Table id="tabelaRelatorio" striped bordered hover>
                      <thead>
                        <tr>
                          <th>Matrícula</th>
                          <th>Nome do Candidato</th>
                          <th>Período de Inscrição</th>
                          <th>Comunidade</th>
                          <th>Presenças</th>
                          <th>Faltas</th>
                        </tr>
                      </thead>
                      <tbody>
                        {dadosRelatorio.map((item, index) => (
                          <tr key={index}>
                            <td>{item.candidatoId}</td>
                            <td>{item.nomeCandidato}</td>
                            <td>{item.periodoInscricao}</td>
                            <td>{item.nomeComunidade}</td>
                            <td>{item.qtdPresenca}</td>
                            <td>{item.qtdFaltas}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </>
                ) : (
                  <p className='text-center'>Nenhum dado disponível</p>
                )}
              </>
            )}
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default RelatorioPresenca;
